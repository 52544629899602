import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Upload from "../EmployerData/components/DelhiveryEarningAdmin";
import Axios from "axios";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const ExternalWorker = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const { showToast } = useContext(AuthContext);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [disablePayments, setDisablePayments] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [sortBy, setSortBy] = useState(100);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sortUrl, setSortUrl] = useState("&partnerId=100");
  const [financialAdmin, setFinancialAdmin] = useState(false);
  const [filterUrl, setFilterUrl] = useState("");
  const [searchByAuto, setsearchByAuto] = useState("");
  const [partner, setPartner] = useState([]);
  const [tab, setTab] = useState({
    invoiceTab: false,
    transactionTab: true,
  });

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const [filters, setFilters] = useState({
    searchBy: "",
    billDate: [null, null],
    zone: "",
    facility_state: "",
    facility_name: "",
    payment_status: "",
    worker_status: "",
    worker_type: "",
    payment_type: "",
    advanceTaken: "",
    bankDetails: "",
  });

  const ApplySort = () => {
    if (sortBy) setSortUrl("&partnerId=" + sortBy);
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
    setCurrentPage(1);
  }, [sortBy]);

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setsearchByAuto(searchBy);
    setCurrentPage(1);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setsearchByAuto();
    setIsFilterApplied(false);
  };

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.getPartner}`).then((response) => {
      let a = [];
      response.data.response.map((e) => {
        if (e.is_active) {
          a.push([e.partner_id, e.company_name]);
        }
      });
      setPartner(a);
      setData2(makeData2(response.data.response));
    });
  }, []);

  const ApplyFilters = (filters) => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=2019-01-01&billTo=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["zone"]) {
      url = `${url}&zone=${filters["zone"]}`;
    }
    if (filters["facility_state"]) {
      url = `${url}&facility_state=${filters["facility_state"].name}`;
    }
    if (filters["facility_name"]) {
      url = `${url}&facility_name=${filters["facility_name"].name}`;
    }
    if (filters["payment_status"]) {
      url = `${url}&payment_status=${filters["payment_status"]}`;
    }
    if (filters["worker_status"]) {
      url = `${url}&worker_status=${filters["worker_status"]}`;
    }
    if (filters["worker_type"] || filters["worker_type"] === 0) {
      url = `${url}&isGold=${filters["worker_type"]}`;
    }
    if (filters["payment_type"]) {
      url = `${url}&paymentType=${filters["payment_type"]}`;
    }
    if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
      url = `${url}&advanceTaken=${filters["advanceTaken"]}`;
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bankdetail=${filters["bankDetails"]}`;
    }
    setFilterUrl(url);
    setFilters(filters);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };

  const columnsOkayGo = useMemo(
    () => [
      {
        Header: "Hub Name",
        accessor: "facilityName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Hub City",
        accessor: "facilityCity",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Hub State",
        accessor: "facilityState",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Hub Address",
        accessor: "address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Earning Per Hour",
        accessor: "earning_per_hour",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Zone",
        accessor: "zone",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Hub Type",
        accessor: "facilityType",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview location",
        accessor: "interview_location",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Spocs",
        accessor: "spoc_name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Coordinates",
        accessor: "hubLatitude",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Is Active",
        accessor: "isActive",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const makeData2 = (response) => {
    let extractedData = [];
    response.map((el) => {
      extractedData.push({
        partnerId: el.partner_id || "",
        partnerName: el.company_name || "",
        logo: el.logo || "",
        insertedOn: el.inserted_on
          ? moment(el.inserted_on).format("DD/MM/YYYY")
          : "",
        paymentConfiguration:
          el.paymentConfiguration === 1
            ? "Shipment Basis"
            : el.paymentConfiguration === 2
            ? "Hourly Basis"
            : el.paymentConfiguration === 3
            ? "Daily Basis"
            : "",
        isActive: el.is_active === 1 ? "Yes" : "No",
      });
      return true;
    });
    return extractedData;
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      if (sortBy === 100) {
        extractedData.push({
          facilityName: el.facilityName || "",
          facilityCity: el.facilityCity || "",
          facilityState: el.facilityState
            ? el.pincode
              ? el.facilityState + ", " + el.pincode
              : el.facilityState
            : "",
          facilityType: el.facilityType || "",
          hubLatitude: el.hubLatitude
            ? el.hubLatitude + "," + el.hubLongitude
            : "",
          rateCard: el.rateCard || null,
          spoc_name: el.spoc_name
            ? el.spoc_contact
              ? el.spoc_name + " - " + el.spoc_contact
              : el.spoc_name || null
            : el.spoc_contact || null,
          zone: el.zone || null,
          address: el.address || null,
          earning_per_hour: el.earning_per_hour || null,
          isActive: el.cluster_active === 1 ? "Yes" : "No",
          interview_location: el.interview_location || "",
        });
      } else {
        extractedData.push({
          facilityName: el.cluster_name || "",
          facilityCity: el.city || "",
          facilityState: el.state ? el.state : "",
          facilityType: el.facilityType || "",
          hubLatitude: el.hub_latitude
            ? el.hub_latitude + "," + el.hub_longitude
            : "",
          rateCard: el.rateCard || null,
          spoc_name: el.spoc_name
            ? el.spoc_contact
              ? el.spoc_name + " - " + el.spoc_contact
              : el.spoc_name || null
            : el.spoc_contact || null,
          zone: el.cluster_zone || null,
          address: el.address || null,
          earning_per_hour: el.earning_per_hour || null,
          isActive: el.cluster_active === 1 ? "Yes" : "No",
          interview_location: el.interview_location || "",
        });
      }
      return true;
    });

    return extractedData;
  };

  //Function to create All record export data
  const columns = columnsOkayGo;

  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.hubListing}?page_no=${
        pageNo - 1
      }${requestedBy}&rows=${rows}${filterUrl}${sortUrl}`
    )
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPaymentListing(perPage, 1);
  }, [filterUrl, sortUrl, localStorage.getItem("roleType"), tab]);

  const handlePageChange = (page) => {
    getPaymentListing(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: [""] },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getPaymentListing(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    rows.map((record, index) => {
      if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
        selectedRowIds[index] = false;
      }
    });
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdHub zIndex1080 header"
                      : column.render("Header") === "Hub Name"
                      ? "stickWorkerNameHub zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : column.render("Header") === "Hub Address"
                      ? "width270 header"
                      : column.id === "Mark onHold"
                      ? financialAdmin
                        ? ""
                        : "displayNone"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Hub Name"
                            ? "stickWorkerNameHub zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : cell.column.Header === "Mark onHold"
                            ? financialAdmin
                              ? ""
                              : "displayNone"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid container xs={12}>
          {" "}
          <Grid>
            <span className="fontSize24 fontWeightBold">Hub List</span>
          </Grid>
        </Grid>
        <Grid item xs={12} className="flexDisplay" alignItems="center">
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8"
            direction="row"
            justify="space-between"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder={"Hub Name & City"}
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            className="flexDisplay padding0"
            direction="row"
            justify="flex-start"
            style={{ gap: "10px" }}
            alignItems="baseline"
          >
            <FormControl
              variant="outlined"
              style={{ minWidth: "100px" }}
              margin="dense"
            >
              <Select
                id="sortBy"
                IconComponent={ExpandMoreIcon}
                value={sortBy}
                style={{ opacity: sortBy === 0 ? "0.5" : "1" }}
                onChange={(event) => {
                  setSortBy(event.target.value);
                }}
                className={classes.select}
                inputProps={{
                  name: "Sort by:",
                  id: "sortBy",
                }}
              >
                <MenuItem key="fitlerByPartner" value={0} disabled={true}>
                  Filter by Partner
                </MenuItem>
                {partner.map((option) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <button
              className={styles.uplaodHubButton}
              onClick={() => {
                setOpenUploadModal(true);
              }}
            >
              Upload Hubs
            </button>
            {!loading && data.length !== 0 && (
              <CSVLink
                data={data}
                filename={`hub listing.csv`}
                className={styles.downloadExcel}
                target="_blank"
              >
                Export Excel
              </CSVLink>
            )}
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openUploadModal && (
          <Upload
            openOnboardingModal={openUploadModal}
            setOpenOnboardingModal={setOpenUploadModal}
            showToast={showToast}
            updateMyData={updateMyData}
            data={data2}
          ></Upload>
        )}
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
