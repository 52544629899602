import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Axios from 'axios'
import { useDebounce } from "use-debounce";
import UrlLinks from "../../../UrlLinks";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import './AutoCompleteFilter.css';


export default function Asynchronous(props) {
  const {filters, setFilters, id, options, data, setData} = props
  const [open, setOpen] = useState(false);
  const [searchBy, setSearchBy] = useState("")

  const [debouncedText] = useDebounce(searchBy, 350);
  let loading = false ;
  const onChangeHandle = (value) => {
    setSearchBy(value)
  };
  
  const getOptionsData =() =>{
    if(debouncedText){
      loading=true
        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.leadCities}${debouncedText}`)
        .then(response =>{
          // console.log("res----->",response)
          loading=false
        })
        .catch((err) => console.log(err))
    }
  }

  useEffect(() => {
    getOptionsData()
    console.log(filters[id])
  }, [debouncedText])

  useEffect(() => {
    console.log(id, filters[id])
  }, [])

  const handleValue = (val) => {
    let list = [];
    val.map((e) => {
      list.push(e.id);
    })
    return list;
  };

  return (
    <div style={{ width: '250px' }}>
      <Autocomplete
        id="multiple-limit-tags"
        multiple
        limitTags={1}
        value = {data}
        onChange={(event, value) => {
          // console.log(value);
          let a = handleValue(value);
          // console.log("ff===>",filters);
          setFilters({...filters, [id]: a.toString()});
          setData(typeof value === 'string' ? value.split(',') : value);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(option) => (
          option.name && option.phoneNumber? option.name + ' - ' + option.phoneNumber : 
          option.name? option.name : 
          option.phoneNumber? option.phoneNumber : ''
        )}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder= {data == ''? 'Name/Phone no.' : ""}
            size="small"
            onChange={(event) => {
              if (event.target.value !== "" || event.target.value !== null) {
                onChangeHandle(event.target.value);
              }
            }}
            className='limitPadding'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div>
                  {loading ? (
                  <CircularProgress color="inherit" size={20}/>
                  ) : <InputAdornment position="end" edge='end'>{params.InputProps.endAdornment}</InputAdornment>}
                </div>
              )
            }}
          />
        )}
      />
    </div>
  );
}
