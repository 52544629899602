import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
import { Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, Select, MenuItem } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import styles from './index.module.scss'
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import SendMsg from './components/SendMsg';
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { AuthContext } from '../../services/AuthProvider'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import UrlLinks from '../../UrlLinks'
import moment from 'moment'
import NoRecord from './noRecord.svg'
import VerifiedSVG from "./verified15x15.svg";
import NotUploadSVG from "./Not uploaded.svg";
import RejectedSVG from "./Rejected.svg";
import FlagSVG from "./Flag.svg";
import PendingSVG from "./Pend.svg";
import "./indeterminateCheckbox.css";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const WorkerData = (props) => {
  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSendMsg, setOpenSendMsg] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [disable, setDisable] = useState(true);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [crossFilter, setCrossFilter] = useState({
    value: "&verificationStatus=2",
    index: 2,
  });
  const [dropFilter, setDropFilter] = useState({ value: "", index: 0 });
  const [totalPages, setTotalPages] = useState(1);
  const [options, setOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(-1);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [docListLoader, setDocListLoader] = useState(null);
  const [downloadDocError, setDownloadDocError] = useState(null);
  const [isRecruiterData, setIsRecruiterData] = useState("");
  const [filters, setFilters] = useState({
    searchBy: "",
    source: "",
    all: true,
    onboardingInitiated: false,
    active: false,
    inactive: false,
    criminalRecord: false,
    temporarilyInactive: false,
    offboardingInitiated: false,
    zone: "",
    facilityState: "",
    facilityName: "",
    facilityCity: "",
    partnerId: "",
    workFor: "",
    workerStatus: "",
    psf: "",
    callStatus: "",
    interviewStatus: "",
    recruiterId: "",
    trainingUpdate: "",
    preferedHub: "",
    appLead: "",
    interviewDate: [null, null],
    firstEarningDate: [null, null],
    leadAddedDate: [null, null],
    bankDetails: "",
    leadCity: "",
    leadSource: "",
    lastEarning: "",
    clientEarningStatus: "",
    clientStatus: "",
    RecruiterAssignedBetween: [null, null],
    idGenerationDate: [null, null],
    IsLmaStatus: "",
    bgvStatus: "",
    lastAppliedBy: "",
  });
  const [tab, setTab] = useState({
    workerDataTab: true,
    recruiterDataTab: false,
  });

  const FirstUpperCase = (str) => {
    let s = "";
    s = str;

    if (s.length > 1) {
      let sub = s.substring(0, 1).toUpperCase();
      let remaining = s.substring(1, s.length);
      return sub + remaining;
    }
  };

  let c = null;
  useEffect(() => {
    Axios.get(`${UrlLinks.baseUrl}/configmaster/getCities`).then((response) => {
      let v = [];
      response.data.response.map((e) => v.push(FirstUpperCase(e.trim())));
      c = v.sort();
      c.unshift("All Cities");
      c = v;
      handleOptions();
    });
  }, []);

  const handleOptions = () => {
    setOptions(
      c.map((option) => {
        return { title: option };
      })
    );
  };

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setsearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
  };

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "casper_id":
        url = "&orderBy=wm.casper_id";
        break;
      case "profile_id":
        url = "&orderBy=wm.worker_id";
        break;
      case "okaygo_id":
        url = "&orderBy=wm.okaygo_id";
        break;
      case "onboarding_date":
        url = "&orderBy=wm.onboarding_initiation_date";
        break;
      case "active_date":
        url = "&orderBy=wm.active_date";
        break;
      case "offboarding_date":
        url = "&orderBy=wm.offboarding_initiation_date";
        break;
      case "inactive_date":
        url = "&orderBy=wm.inactive_date";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const updateFilterUrl = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["onboardingInitiated"]) {
      url = `${url}&workerStatus=2,5`;
    }
    if (filters["status"]) {
      url = `${url}&status=${filters["status"]}`;
    }
    if (filters["offboardingInitiated"]) {
      url = `${url}&workerStatus=4`;
    }
    if (filters["temporarilyInactive"]) {
      url = `${url}&workerStatus=6,8`;
    }
    if (filters["criminalRecord"]) {
      url = `${url}&criminal_record=1`;
    }
    if (filters["partnerId"]) {
      url = `${url}&partnerId=${filters["partnerId"].id}`;
    }
    if (filters["facilityName"]) {
      url = `${url}&facilityName=${filters["facilityName"].name}`;
    }
    if (filters["facilityCity"]) {
      url = `${url}&facilityCity=${filters["facilityCity"].name}`;
    }
    if (filters["facilityState"]) {
      url = `${url}&facilityState=${filters["facilityState"].name}`;
    }
    if (filters["source"]) {
      url = `${url}&source=${filters["source"].id}`;
    }
    if (filters["clientStatus"]) {
      url = `${url}&clientStatus=${filters["clientStatus"].id}`;
    }
    if (filters["clientEarningStatus"]) {
      url = `${url}&earningStatus=${filters["clientEarningStatus"].id}`;
    }
    if (filters["workerStatus"]) {
      url = `${url}&workerStatus=${filters["workerStatus"].id}`;
    }
    if (filters["workFor"]) {
      url = `${url}&workFor=${filters["workFor"]}`;
    }
    if (filters["psf"]) {
      url = `${url}&psf=${filters["psf"].id}`;
    }
    if (filters["leadSource"]) {
      url = `${url}&leadSource=${filters["leadSource"].id}`;
    }
    if (filters["callStatus"]) {
      url = `${url}&callStatus=${filters["callStatus"].id}`;
    }
    if (filters["lastAppliedBy"]) {
      url = `${url}&lastAppliedBy=${filters["lastAppliedBy"].id}`;
    }
    if (filters["appStatus"]) {
      url = `${url}&appStatus=${filters["appStatus"].id}`;
    }
    if (filters["interviewStatus"]) {
      url = `${url}&interviewStatus=${filters["interviewStatus"].id}`;
    }
    if (filters["recruiterId"]) {
      url = `${url}&recruiterId=${filters["recruiterId"].id}`;
    }
    if (filters["trainingUpdate"]) {
      url = `${url}&trainingUpdate=${filters["trainingUpdate"].id}`;
    }
    if (filters["preferedHub"]) {
      url = `${url}&preferedHub=${filters["preferedHub"].id}`;
    }
    if (filters["appLead"]) {
      url = `${url}&appLead=${filters["appLead"].id}`;
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bankdetail=${filters["bankDetails"].id}`;
    }
    if (filters["leadCity"] || filters["leadCity"] === 0) {
      url = `${url}&leadCity=${filters["leadCity"].id}`;
    }
    if (filters["IsLmaStatus"]) {
      url = `${url}&is_lma_worker=${filters["IsLmaStatus"].id}`;
    }
    if (filters["bgvStatus"]) {
      url = `${url}&bgvStatus=${filters["bgvStatus"].id}`;
    }
    if (
      filters["RecruiterAssignedBetween"][0] &&
      !filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=${moment(
        filters["RecruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=2019-01-01&recruiterAssingedTO=${moment(
        filters["RecruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (
      filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=${moment(
        filters["RecruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(
        filters["RecruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["idGenerationDate"][0] && !filters["idGenerationDate"][1])
      url = `${url}&idGeneratedFrom=${moment(
        filters["idGenerationDate"][0]
      ).format("YYYY-MM-DD")}&idGeneratedTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&idGeneratedFrom=2019-01-01&idGeneratedTO=${moment(
        filters["idGenerationDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["idGenerationDate"][0] && filters["idGenerationDate"][1])
      url = `${url}&idGeneratedFrom=${moment(
        filters["idGenerationDate"][0]
      ).format("YYYY-MM-DD")}&idGeneratedTO=${moment(
        filters["idGenerationDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["interviewDate"][0] && !filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=2019-01-01&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["leadAddedDate"][0] && !filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(
        filters["leadAddedDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(filters["leadAddedDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["firstEarningDate"][0] && !filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=2019-01-01&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["lastEarning"].id === "last3days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["lastEarning"].id === "last5days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(4, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["lastEarning"].id === "last7days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    return url;
  };

  const resetFiltersWithoutSearch = () => {
    const filterList = {
      source: "",
      searchBy: filters.searchBy,
      zone: "",
      facilityState: "",
      facilityName: "",
      partnerId: "",
      facilityCity: "",
      workFor: "",
      workerStatus: "",
      psf: "",
      callStatus: "",
      interviewStatus: "",
      recruiterId: "",
      trainingUpdate: "",
      preferedHub: "",
      appLead: "",
      interviewDate: [null, null],
      firstEarningDate: [null, null],
      leadAddedDate: [null, null],
      bankDetails: "",
      leadCity: "",
      leadSource: "",
      lastEarning: "",
      appStatus: "",
      clientEarningStatus: "",
      clientStatus: "",
      RecruiterAssignedBetween: [null, null],
      idGenerationDate: [null, null],
      IsLmaStatus: "",
      bgvStatus: "",
      lastAppliedBy: "",
    };
    setFilters(filterList);
    setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "");
    setCurrentPage(1);
    return filterList;
  };

  const workerDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              user={row}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Lead id/OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return (
            <Link
              to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
              target="_blank"
              className="nameLink"
            >
              {row.row.original.name}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.clients.map((el) => {
                return (
                  <div>
                    <b>{el[0]}: </b>
                    {el[1]}
                  </div>
                );
              })}
            </div>
          );
        },

        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Document Verification Status",
        accessor: "DVStatus",
        Cell: (row) => {
          return (
            <Grid container style={{ lineHeight: "30px", display: "flex" }}>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makeDocumentstatus(
                  "Aadhaar Card",
                  row.row.original.aadharStatus
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makeDocumentstatus("PAN Card", row.row.original.panStatus)}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makeDocumentstatus(
                  "Driving License",
                  row.row.original.dlStatus
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makeDocumentstatus(
                  "Current Address",
                  row.row.original.addressStatus
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makeDocumentstatus(
                  "Photo",
                  row.row.original.profile_pic_status
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Link
                  to={{
                    pathname: `/verificationdetails/${
                      row.row.original.userId
                    }/${crossFilter.index}/${makeStatus(crossFilter.index)}}}`,
                  }}
                  state={{ from: localStorage.setItem("location", "worker") }}
                  className="nameLink"
                  target="_blank"
                >
                  View details {">"}
                </Link>
              </Grid>
            </Grid>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [crossFilter]
  );

  const recruiterDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              user={row}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Recruiter ID",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return <span className="nameLink">{row.row.original.name}</span>;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Card Status",
        accessor: "panCardStatus",
        Cell: (row) => {
          return (
            <Grid container style={{ lineHeight: "30px", display: "flex" }}>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makePanStatus(row.row.original.pan_status)}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left", marginTop: "4px" }}>
                <Link
                  to={{
                    pathname: `/verificationdetails/${
                      row.row.original.userId
                    }/${crossFilter.index}/${makeStatus(crossFilter.index)}}}`,
                  }}
                  state={{
                    from: localStorage.setItem("location", "recruiter"),
                  }}
                  className="nameLink"
                  target="_blank"
                >
                  View details {">"}
                </Link>
              </Grid>
            </Grid>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [crossFilter]
  );

  const makeDocumentstatus = (doc, status) => {
    switch (status) {
      case 0:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={NotUploadSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      case 1:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={VerifiedSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={FlagSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      case 3:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={RejectedSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      case 4:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={PendingSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      default:
        break;
    }
  };

  const makePanStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={NotUploadSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> Not Filled</div>
          </div>
        );
      case 1:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={VerifiedSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> Verified</div>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={FlagSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> Flagged</div>
          </div>
        );
      case 3:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={RejectedSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> Rejected</div>
          </div>
        );
      case 4:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={PendingSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> Pending</div>
          </div>
        );
      default:
        break;
    }
  };

  const handleSendMsg = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1]) {
        idList += data[parseFloat(id[0])].userId + ",";
      }
      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    setSelectedUserIds(idList);
    setOpenSendMsg(!openSendMsg);
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let clients = [];
    response.content.map((el) => {
      clients = [];
      extractedData.push({
        userId: el.userId,
        okaygoId:
          el.okayGoId !== null
            ? el.okayGoId
            : el.leadId !== null
            ? el.leadId
            : "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        contactNo: el.phoneNumber || "",
        panStatus: el.panStatus !== null ? el.panStatus : 0,
        aadharStatus: el.aadharStatus !== null ? el.aadharStatus : 0,
        dlStatus: el.dlStatus !== null ? el.dlStatus : 0,
        addressStatus: el.addressStatus !== null ? el.addressStatus : 0,
        profile_pic_status:
          el.profile_pic_status !== null ? el.profile_pic_status : 0,
        pan_status: el.panStatus !== null ? el.panStatus : 0,
        clientWorkerData:
          el.clientList !== null
            ? el.clientList.map((cl) => {
                clients.push([
                  cl.clientName,
                  createWorkerStatus(cl.clientIdStatus),
                ]);
              })
            : "",
        clients: clients,
      });
      return true;
    });
    return extractedData;
  };

  const createWorkerStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "Offboarded";
        break;
      case 1:
        text = "Active";
        break;
      default:
        break;
    }
    return text;
  };

  const makeWorkerData = (response) => {
    let extractedData = [];
    let clients = [];

    response.map((el) => {
      let temp;
      if (tab.workerDataTab) {
        temp = {
          "LeadId/okaygoId":
            el.okayGoId !== null
              ? el.okayGoId
              : el.leadId !== null
              ? el.leadId
              : "",
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
          contactNo: el.phoneNumber || "",
          PanCardStatus:
            el.panStatus !== null ? makeStatus(el.panStatus) : "Not Filled",
          AadharStatus:
            el.aadharStatus !== null
              ? makeStatus(el.aadharStatus)
              : "Not Filled",
          DLStatus:
            el.dlStatus !== null ? makeStatus(el.dlStatus) : "Not Filled",
          AddressStatus:
            el.addressStatus !== null
              ? makeStatus(el.addressStatus)
              : "Not Filled",
          Profile_pic_status:
            el.profile_pic_status !== null
              ? makeStatus(el.profile_pic_status)
              : "Not Filled",
          clients: clients.toString(),
        };
      } else {
        temp = {
          recruiterId: el.okayGoId,
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
          contactNo: el.phoneNumber || "",
          PanCardStatus:
            el.panStatus !== null ? makeStatus(el.panStatus) : "Not Filled",
        };
      }
      clients = [];
      extractedData.push(temp);
      return true;
    });
    return extractedData;
  };

  const makeStatus = (status) => {
    switch (status) {
      case 0:
        return "Not Filled";
      case 1:
        return "Verified";
      case 2:
        return "Flagged";
      case 3:
        return "Rejected";
      case 4:
        return "Pending";
      default:
        break;
    }
  };

  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const createAllRecordExcel = (obj) => {
    setAllRecordLoading(true);
    let requestedBy = "";

    let worker_url = `${urlLinks.baseUrl}${urlLinks.verificationAPIs.listing}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${crossFilter.value}${dropFilter.value}&exportExcel=1`;
    let recruiter_url = `${urlLinks.baseUrl}${urlLinks.verificationAPIs.listing}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${crossFilter.value}${dropFilter.value}${isRecruiterData}&exportExcel=1`;
    Axios.get(tab.workerDataTab ? worker_url : recruiter_url).then(
      (response) => {
        if (response) {
          setAllRecord(makeWorkerData(response.data.response));
        }
      }
    );
  };

  const handleOpenExportModal = () => {
    createAllRecordExcel();
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getExternalWorkerList = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";

    let worker_url = `${urlLinks.baseUrl}${
      urlLinks.verificationAPIs.listing
    }?page_no=${
      pageNo - 1
    }&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${
      crossFilter.value
    }${dropFilter.value}`;
    let recruiter_url = `${urlLinks.baseUrl}${
      urlLinks.verificationAPIs.listing
    }?page_no=${
      pageNo - 1
    }&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${
      crossFilter.value
    }${dropFilter.value}${isRecruiterData}`;
    Axios.get(tab.workerDataTab ? worker_url : recruiter_url)
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setAllRecord([]);
  }, [
    filterUrl,
    sortUrl,
    sortDirectionUrl,
    localStorage.getItem("roleType"),
    crossFilter,
    dropFilter,
    tab,
  ]);

  const columns = tab.workerDataTab ? workerDataColumn : recruiterDataColumn;
  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      docListLoader,
      downloadDocError,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisable(disableButton);
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table" style={{ width: "100%" }}>
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameV2 zIndex1080 header"
                      : column.render("Header") === "Profile Completion"
                      ? "" // not visible to hrbp and super hrbp
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header width25"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200"
                      : "header"
                  }
                >
                  <span
                    style={
                      column.render("Header") === "addressGoogle" ||
                      column.render("Header") === "documentsPending"
                        ? { width: "250px" }
                        : {}
                    }
                  >
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  const handleTab = (event, name) => {
    // RESET filters except search bar
    resetFiltersWithoutSearch();
    //////////////////////////////////

    if (name === "1") {
      setTab({ ...tab, workerDataTab: true, recruiterDataTab: false });
      setIsRecruiterData("");
      setCurrentPage(1);
    } else if (name === "2") {
      setTab({ ...tab, workerDataTab: false, recruiterDataTab: true });
      setIsRecruiterData("&isRecruiter=1");
      setCurrentPage(1);
    }
  };

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12} className="flexDisplay justifyFlexStart">
          <span className="fontSize24 fontWeightBold marginRight20">
            Document Details Verification
          </span>
          <Button
            onClick={(event) => {
              if (tab.recruiterDataTab === true) {
                handleTab(event, "1");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Worker
          </Button>
          <Button
            onClick={(event) => {
              if (tab.workerDataTab === true) {
                handleTab(event, "2");
              }
            }}
            className={`statusButtons ${
              tab.recruiterDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Recruiter
          </Button>
        </Grid>
        <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
          <Grid
            item
            xs={7}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder={
                tab.recruiterDataTab
                  ? "Contact number / Recruiter ID "
                  : "Enter Aadhar No./ Contact number / OkayGo ID / Client ID"
              }
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
        </Grid>
        <Grid style={{ paddingTop: "8px" }}>
          <Button
            variant="contained"
            className="dataButton"
            style={{ marginTop: "7px" }}
            onClick={handleOpenExportModal}
            disabled={allRecordLoading || !data.length > 0}
          >
            Export to Excel
          </Button>
          {allRecordLoading ? (
            <span style={{ marginRight: "5px" }}>
              Please wait...it might take few minutes
            </span>
          ) : allRecord.length > 0 ? (
            <CSVLink
              data={allRecord}
              filename={
                isRecruiterData === ""
                  ? `Worker Data.csv`
                  : `Recruiter Data.csv`
              }
              className="btn btn-primary blueColor downloadExcelButton"
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
                marginRight: "20px",
                fontWeight: "500",
              }}
            >
              Download now
            </CSVLink>
          ) : null}

          {tab.workerDataTab
            ? (localStorage.getItem("roleType") === "2" ||
                localStorage.getItem("roleType") === "6") && (
                <Button
                  variant="contained"
                  className="dataButton"
                  style={{ marginLeft: "16px", marginTop: "7px" }}
                  onClick={() => handleSendMsg()}
                  disabled={disable}
                >
                  Send Message
                </Button>
              )
            : ""}
        </Grid>
        <Grid
          container
          item
          xs={12}
          className="flexDisplay paddingTop16 justifyFlexStart"
        >
          {
            <Grid item xs={10}>
              <Button
                onClick={(event) => {
                  {
                    setCrossFilter({
                      value: "&verificationStatus=2",
                      index: 2,
                    });
                  }
                }}
                className={`statusButtons marginRight20 ${
                  crossFilter.value === "&verificationStatus=2"
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                Flagged
              </Button>
              <Button
                onClick={(event) => {
                  {
                    setCrossFilter({
                      value: "&verificationStatus=4",
                      index: 4,
                    });
                  }
                }}
                className={`statusButtons marginRight20 ${
                  crossFilter.value === "&verificationStatus=4"
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                Pending
              </Button>
              <Button
                onClick={(event) => {
                  {
                    setCrossFilter({
                      value: "&verificationStatus=0",
                      index: 0,
                    });
                  }
                }}
                className={`statusButtons marginRight20 ${
                  crossFilter.value === "&verificationStatus=0"
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                Not filled
              </Button>
              <Button
                onClick={(event) => {
                  {
                    setCrossFilter({
                      value: "&verificationStatus=1",
                      index: 1,
                    });
                  }
                }}
                className={`statusButtons marginRight20 ${
                  crossFilter.value === "&verificationStatus=1"
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                Verified
              </Button>
              <Button
                onClick={(event) => {
                  setCrossFilter({ value: "&verificationStatus=3", index: 3 });
                }}
                className={`statusButtons marginRight20 ${
                  crossFilter.value === "&verificationStatus=3"
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                Rejected
              </Button>
              <Button
                onClick={(event) => {
                  setCrossFilter({ value: "", index: 0 });
                }}
                className={`statusButtons marginRight20 ${
                  crossFilter.value === ""
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                All
              </Button>
            </Grid>
          }
          {tab.workerDataTab ? (
            <Grid item xs={2}>
              <FormControl variant="outlined" fullWidth margin="dense">
                <Select
                  id="sortBy"
                  IconComponent={ExpandMoreIcon}
                  value={dropFilter.index}
                  onChange={(event) => {
                    if (event.target.value === 0) {
                      setDropFilter({ value: "", index: event.target.value });
                    } else if (event.target.value === 1) {
                      setDropFilter({
                        value: "&userStatus=1",
                        index: event.target.value,
                      });
                    } else if (event.target.value === 2) {
                      setDropFilter({
                        value: "&userStatus=2",
                        index: event.target.value,
                      });
                    } else if (event.target.value === 3) {
                      setDropFilter({
                        value: "&userStatus=3",
                        index: event.target.value,
                      });
                    }
                  }}
                >
                  <MenuItem key="fitlerByPartner" value={0} disabled={true}>
                    Filter by Status
                  </MenuItem>
                  <MenuItem key="fitlerByPartner" value={1}>
                    Workers
                  </MenuItem>
                  <MenuItem key="fitlerByPartner" value={2}>
                    Leads in process
                  </MenuItem>
                  <MenuItem key="fitlerByPartner" value={3}>
                    Non applicant
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openSendMsg && (
          <SendMsg
            isRecruiterData={isRecruiterData}
            filterUrl={filterUrl}
            open={openSendMsg}
            setOpen={setOpenSendMsg}
            userIds={selectedUserIds}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default WorkerData;
