import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Drawer,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import AssignToVerifierModal from "./AssignToVerifierModal";
import UrlLinks from "../../UrlLinks";
import moment from "moment";
import NoRecord from "./noRecord.svg";
import ExcelSvg from "./Excel.svg";
import "./indeterminateCheckbox.css";
import FilterDrawer from "./filters";
import FilterIcon from "../../assets/filterIcon.png";
import WarningIcon from "@mui/icons-material/Warning";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const showToast = (type, message, id) => {
  if (id) {
    if (type === "success") toast.success(message, { toastId: id });
    else if (type === "error") toast.error(message);
  } else {
    if (type === "success") toast.success(message);
    else if (type === "error") toast.error(message);
  }
};

const WorkerData = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [disable, setDisable] = useState(true);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [caseIds, setCaseIds] = useState([]);
  const [pincodeSelected, setPincodeSelected] = useState([]);
  const [dropFilter, setDropFilter] = useState({ value: "", index: 0 });
  const [assignFilterString, setassignFilterString] = useState("");
  const [actions, setActions] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(-1);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [docListLoader, setDocListLoader] = useState(null);
  const [downloadDocError, setDownloadDocError] = useState(null);
  const [searchError, setSearchError] = React.useState(false);
  const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false);
  const [isUniquePincode, setIsUniquePincode] = useState(true);
  const [actionToPass, setActionToPass] = useState("");
  const [clientName, setClientName] = useState([]);
  const [multiSelectId, setMultiSelectId] = useState({
    clientName: [],
  });
  const [filters, setFilters] = useState({
    searchBy: "",
    open: true,
    picked: false,
    completed: false,
    returned: false,
    statusSince: "",
    statusByDate: [null, null],
    verificationStatus: null,
    city: "",
    pincode: null,
    clientName: "",
    verifiers: null,
  });
  const [tab, setTab] = useState({
    open: true,
    picked: false,
    completed: false,
    returned: false,
  });
  const [assignToVerifierEnable, setAssignToVerifierEnable] = useState(false);

  useEffect(() => {
    let pinObj = {};
    pincodeSelected.forEach((ele) => {
      pinObj[ele.pincode] = ele.pincode;
    });
    if (Object.values(pinObj).length > 1) {
      setIsUniquePincode(false);
    } else {
      setIsUniquePincode(true);
    }
  }, [pincodeSelected]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  function removeExtraSpaces(string) {
    return string.replace(/\s{2,}/g, " ");
  }

  useEffect(() => {
    let searchByVar = removeExtraSpaces(searchBy);
    let count = 0;
    count += (searchByVar.match(/ /g) || []).length;
    count += (searchByVar.match(/,/g) || []).length;
    count++;
    if (count > 100) {
      setSearchError(true);
    } else if (count <= 100) {
      setSearchError(false);
    }
  }, [searchBy]);

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setsearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
  };

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "casper_id":
        url = "&orderBy=wm.casper_id";
        break;
      case "profile_id":
        url = "&orderBy=wm.worker_id";
        break;
      case "okaygo_id":
        url = "&orderBy=wm.okaygo_id";
        break;
      case "onboarding_date":
        url = "&orderBy=wm.onboarding_initiation_date";
        break;
      case "active_date":
        url = "&orderBy=wm.active_date";
        break;
      case "offboarding_date":
        url = "&orderBy=wm.offboarding_initiation_date";
        break;
      case "inactive_date":
        url = "&orderBy=wm.inactive_date";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  const ApplyFilters = () => {
    let url = updateFilterUrl(filters);
    setFilterUrl(url);
    setFilters(filters);
    setClientName(clientName);
    setCurrentPage(1);
  };

  const updateFilterUrl = (filters) => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["statusSince"]) {
      url = `${url}&statusSince=${filters["statusSince"].id}`;
    }
    if (filters["statusByDate"][0])
      url = `${url}&startDate=${moment(filters["statusByDate"][0]).format(
        "YYYY-MM-DD"
      )}`;
    if (filters["statusByDate"][1])
      url = `${url}&endDate=${moment(filters["statusByDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["verificationStatus"]) {
      url = `${url}&verificationStatus=${filters["verificationStatus"].id}`;
    }
    if (filters["city"]) {
      url = `${url}&city=${filters["city"].name}`;
    }
    if (filters["pincode"]) {
      url = `${url}&pincode=${filters["pincode"].name}`;
    }
    if (filters["clientName"]) {
      url = `${url}&clientName=${filters["clientName"].name}`;
    }
    if (filters["verifiers"]) {
      url = `${url}&verifiers=${filters["verifiers"]}`;
    }

    return url;
  };

  const resetFilters = () => {
    const filterList = {
      source: "",
      searchBy: "",
      open: true,
      picked: false,
      completed: false,
      returned: false,
      statusSince: "",
      statusByDate: [null, null],
      verificationStatus: null,
      city: "",
      pincode: null,
      clientName: "",
      verifiers: null,
    };
    setFilters(filterList);
    setFilterUrl("");
    setClientName([]);
    setMultiSelectId({
      clientName: [],
    });
    setIsFilterApplied(true);
    setCurrentPage(1);
    return filterList;
  };

  const resetFiltersWithoutSearch = () => {
    const filterList = {
      source: "",
      searchBy: filters.searchBy,
      open: true,
      picked: false,
      completed: false,
      returned: false,
      statusSince: "",
      statusByDate: [null, null],
      verificationStatus: null,
      city: "",
      pincode: null,
      clientName: "",
      verifiers: null,
    };
    setFilters(filterList);
    setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "");
    setClientName([]);
    setMultiSelectId({
      clientName: [],
    });
    setIsFilterApplied(true);
    setCurrentPage(1);
    return filterList;
  };

  const verificationOpenColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps, rows }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                let isChecked = true;
                rows.forEach((ele) => {
                  if (!ele.isSelected) {
                    isChecked = false;
                  }
                });
                if (!isChecked) {
                  let caseIdArr = rows.map((ele) => {
                    return ele.original.caseId;
                  });
                  let pinCodeArr = rows.map((ele) => ({
                    id: ele.id,
                    pincode: ele.original.pincode,
                  }));
                  setCaseIds(caseIdArr);
                  setPincodeSelected(pinCodeArr);
                }
                if (isChecked) {
                  let caseIdArr = [];
                  setCaseIds(caseIdArr);
                  setPincodeSelected([]);
                }
              }}
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                if (!row.getToggleRowSelectedProps().checked) {
                  setCaseIds((prev) => [...prev, row.original.caseId]);
                  setPincodeSelected((prev) => [
                    ...prev,
                    { pincode: row.original.pincode, id: row.id },
                  ]);
                }
                if (row.getToggleRowSelectedProps().checked) {
                  let newcaseIds = caseIds.filter(
                    (ele) => ele !== row.original.caseId
                  );
                  let newPincodes = pincodeSelected.filter(
                    (ele) => ele.id !== row.id
                  );
                  setCaseIds(newcaseIds);
                  setPincodeSelected(newPincodes);
                }
              }}
              {...row.getToggleRowSelectedProps()}
              user={row}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Case id",
        accessor: "caseId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client name",
        accessor: "clientName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Candidate name & no.",
        accessor: "candidateName",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.candidateName}</div>
              <div>{row.row.original.phoneNumber}</div>
              {row.row.original.altPhoneNumber ? (
                <div>Alt. no. - {row.row.original.altPhoneNumber}</div>
              ) : (
                ""
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address",
        accessor: "address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Pincode",
        accessor: "pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Employee Id",
        accessor: "employeeId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Opened On",
        accessor: "openedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.openedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.openedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Case status",
        accessor: "caseStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned verifier",
        accessor: "assignedVerifier",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verification status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "TAT",
        accessor: "tat",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [caseIds, pincodeSelected]
  );

  const verificationPickedColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps, rows }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                let isChecked = true;
                rows.forEach((ele) => {
                  if (!ele.isSelected) {
                    isChecked = false;
                  }
                });
                if (!isChecked) {
                  let caseIdArr = rows.map((ele) => {
                    return ele.original.caseId;
                  });
                  let pinCodeArr = rows.map((ele) => ({
                    id: ele.id,
                    pincode: ele.original.pincode,
                  }));
                  setCaseIds(caseIdArr);
                  setPincodeSelected(pinCodeArr);
                }
                if (isChecked) {
                  let caseIdArr = [];
                  setCaseIds(caseIdArr);
                  setPincodeSelected([]);
                }
              }}
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                if (!row.getToggleRowSelectedProps().checked) {
                  setCaseIds((prev) => [...prev, row.original.caseId]);
                  setPincodeSelected((prev) => [
                    ...prev,
                    { pincode: row.original.pincode, id: row.id },
                  ]);
                }
                if (row.getToggleRowSelectedProps().checked) {
                  let newcaseIds = caseIds.filter(
                    (ele) => ele !== row.original.caseId
                  );
                  let newPincodes = pincodeSelected.filter(
                    (ele) => ele.id !== row.id
                  );
                  setCaseIds(newcaseIds);
                  setPincodeSelected(newPincodes);
                }
              }}
              {...row.getToggleRowSelectedProps()}
              user={row}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Case id",
        accessor: "caseId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client name",
        accessor: "clientName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Candidate name & no.",
        accessor: "candidateName",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.candidateName}</div>
              <div>{row.row.original.phoneNumber}</div>
              {row.row.original.altPhoneNumber ? (
                <div>Alt. no. - {row.row.original.altPhoneNumber}</div>
              ) : (
                ""
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address",
        accessor: "address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Pincode",
        accessor: "pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Employee Id",
        accessor: "employeeId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Opened On",
        accessor: "openedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.openedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.openedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Picked On",
        accessor: "pickedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.pickedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.pickedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Case status",
        accessor: "caseStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned verifier",
        accessor: "assignedVerifier",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verification status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "TAT",
        accessor: "tat",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [caseIds, pincodeSelected]
  );

  const verificationCompletedColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps, rows }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                let isChecked = true;
                rows.forEach((ele) => {
                  if (!ele.isSelected) {
                    isChecked = false;
                  }
                });
                if (!isChecked) {
                  let caseIdArr = rows.map((ele) => {
                    return ele.original.caseId;
                  });
                  let pinCodeArr = rows.map((ele) => ({
                    id: ele.id,
                    pincode: ele.original.pincode,
                  }));
                  setCaseIds(caseIdArr);
                  setPincodeSelected(pinCodeArr);
                }
                if (isChecked) {
                  let caseIdArr = [];
                  setCaseIds(caseIdArr);
                  setPincodeSelected([]);
                }
              }}
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                if (!row.getToggleRowSelectedProps().checked) {
                  setCaseIds((prev) => [...prev, row.original.caseId]);
                  setPincodeSelected((prev) => [
                    ...prev,
                    { pincode: row.original.pincode, id: row.id },
                  ]);
                  if (row.original.verificationStatus === "green") {
                    setAssignToVerifierEnable(true);
                  }
                }
                if (row.getToggleRowSelectedProps().checked) {
                  let newcaseIds = caseIds.filter(
                    (ele) => ele !== row.original.caseId
                  );
                  if (row.original.verificationStatus === "green") {
                    setAssignToVerifierEnable(false);
                  }
                  let newPincodes = pincodeSelected.filter(
                    (ele) => ele.id !== row.id
                  );
                  setCaseIds(newcaseIds);
                  setPincodeSelected(newPincodes);
                }
              }}
              {...row.getToggleRowSelectedProps()}
              user={row}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Case id",
        accessor: "caseId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client name",
        accessor: "clientName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Candidate name & no.",
        accessor: "candidateName",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.candidateName}</div>
              <div>{row.row.original.phoneNumber}</div>
              {row.row.original.altPhoneNumber ? (
                <div>Alt. no. - {row.row.original.altPhoneNumber}</div>
              ) : (
                ""
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address",
        accessor: "address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Pincode",
        accessor: "pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Employee Id",
        accessor: "employeeId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Opened On",
        accessor: "openedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.openedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.openedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Picked On",
        accessor: "pickedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.pickedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.pickedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Completed On",
        accessor: "completedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.completedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.completedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Case status",
        accessor: "caseStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned verifier",
        accessor: "assignedVerifier",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verification status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "TAT",
        accessor: "tat",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [caseIds, assignToVerifierEnable, pincodeSelected]
  );

  const verificationReturnedColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps, rows }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                let isChecked = true;
                rows.forEach((ele) => {
                  if (!ele.isSelected) {
                    isChecked = false;
                  }
                });
                if (!isChecked) {
                  let caseIdArr = rows.map((ele) => {
                    return ele.original.caseId;
                  });
                  let pinCodeArr = rows.map((ele) => ({
                    id: ele.id,
                    pincode: ele.original.pincode,
                  }));
                  setCaseIds(caseIdArr);
                  setPincodeSelected(pinCodeArr);
                }
                if (isChecked) {
                  let caseIdArr = [];
                  setCaseIds(caseIdArr);
                  setPincodeSelected([]);
                }
              }}
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                if (!row.getToggleRowSelectedProps().checked) {
                  setCaseIds((prev) => [...prev, row.original.caseId]);
                  setPincodeSelected((prev) => [
                    ...prev,
                    { pincode: row.original.pincode, id: row.id },
                  ]);
                }
                if (row.getToggleRowSelectedProps().checked) {
                  let newcaseIds = caseIds.filter(
                    (ele) => ele !== row.original.caseId
                  );
                  let newPincodes = pincodeSelected.filter(
                    (ele) => ele.id !== row.id
                  );
                  setCaseIds(newcaseIds);
                  setPincodeSelected(newPincodes);
                }
              }}
              {...row.getToggleRowSelectedProps()}
              user={row}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Case id",
        accessor: "caseId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client name",
        accessor: "clientName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Candidate name & no.",
        accessor: "candidateName",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.candidateName}</div>
              <div>{row.row.original.phoneNumber}</div>
              {row.row.original.altPhoneNumber ? (
                <div>Alt. no. - {row.row.original.altPhoneNumber}</div>
              ) : (
                ""
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address",
        accessor: "address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Pincode",
        accessor: "pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Employee Id",
        accessor: "employeeId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Opened On",
        accessor: "openedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.openedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.openedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Picked On",
        accessor: "pickedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.pickedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.pickedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Returned On",
        accessor: "returnedOn",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.returnedOn}</div>
              <div
                style={{ borderBottom: "1px solid #AFAFAF", margin: "15px 0" }}
              ></div>
              <div>Since : Last {row.row.original.returnedSince} hours</div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Case status",
        accessor: "caseStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned verifier",
        accessor: "assignedVerifier",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verification status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "TAT",
        accessor: "tat",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [caseIds, pincodeSelected]
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let clients = [];
    response.content.map((el) => {
      clients = [];
      extractedData.push({
        caseId: el.caseId !== null ? el.caseId : "",
        clientName: el.clientName !== null ? el.clientName : "",
        candidateName: el.candidateName || "",
        phoneNumber: el.phoneNumber || "",
        altPhoneNumber: el.altPhoneNumber || "",
        address: el.address ? el.address : "",
        pincode: el.pincode ? el.pincode : "",
        employeeId: el.employerId ? el.employerId : "",
        openedOn: el.openedOn
          ? moment(el.openedOn).format("D MMM, YYYY h:mm a")
          : "-",
        pickedOn: el.pickedOn
          ? moment(el.pickedOn).format("D MMM, YYYY h:mm a")
          : "-",
        completedOn: el.completedOn
          ? moment(el.completedOn).format("D MMM YYYY, h:mm a")
          : "-",
        returnedOn: el.returnedOn
          ? moment(el.returnedOn).format("D MMM, YYYY h:mm a")
          : "-",
        openedSince: el.openedSince ? el.openedSince : "-",
        pickedSince: el.pickedSince ? el.pickedSince : "-",
        completedSince: el.completedSince ? el.completedSince : "-",
        returnedSince: el.returnedSince ? el.returnedSince : "-",
        openRedFlag: el.openRedFlag,
        pickedRedFlag: el.pickedRedFlag,
        caseStatus: el.caseStatus ? el.caseStatus : "open",
        assignedVerifier: `${
          el.firstName
            ? el.firstName + (el.lastName ? " " + el.lastName : "")
            : "-"
        }`,
        verificationStatus: el.verificationStatus ? el.verificationStatus : "-",
        tat: el.tat ? Math.trunc(el.tat) + " days" : "",
      });
      return true;
    });
    return extractedData;
  };

  const makeVerificationCaseData = (response) => {
    let extractedData = [];
    response.map((el) => {
      let temp = {
        caseId: el.caseId !== null ? el.caseId : "",
        clientName: el.clientName !== null ? el.clientName : "",
        "Candidate Name & Number":
          el.candidateName +
            `${el.phoneNumber ? " - " + el.phoneNumber : ""}` +
            `${el.altPhoneNumber ? " / " + el.altPhoneNumber : ""}` || "",
        Address: el.address ? el.address : "",
        pincode: el.pincode ? el.pincode : "",
        employeeId: el.employerId ? el.employerId : "",
        openedOn: el.openedOn ? el.openedOn : "",
        caseStatus: el.caseStatus === null ? "open" : el.caseStatus,
        assignedVerifier:
          el.firstName + `${el.lastName ? " " + el.lastName : ""}` || "-",
        verificationStatus: el.verificationStatus ? el.verificationStatus : "",
        tat: el.tat ? el.tat : "",
      };
      extractedData.push(temp);
      return true;
    });
    return extractedData;
  };

  const updateMyData = () => {
    getVerificationList(perPage, currentPage);
  };

  const createAllRecordExcel = (obj) => {
    setAllRecordLoading(true);
    let requestedBy = "";
    let excelExportedBy = localStorage.getItem("userID");
    if (localStorage.getItem("roleType") === "1") {
      requestedBy = `&requestedById=${localStorage.getItem("userID")}`;
    }
    if (localStorage.getItem("roleType")) {
      let worker_url = `${urlLinks.baseUrl}${urlLinks.verificationCases.listing}?excelExport=1${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${dropFilter.value}`;
      Axios.post(worker_url).then((response) => {
        if (response) {
          setAllRecord(makeVerificationCaseData(response.data.response));
        }
      });
    }
  };

  const handleOpenExportModal = () => {
    setExportModal(true);
    createAllRecordExcel();
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getVerificationList = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";
    let caseStatus = 1;
    if (tab.picked) {
      caseStatus = 2;
    }
    if (tab.completed) {
      caseStatus = 3;
    }
    if (tab.returned) {
      caseStatus = 4;
    }
    if (localStorage.getItem("roleType")) {
      let worker_url = `${urlLinks.baseUrl}${
        urlLinks.verificationCases.listing
      }?page_no=${
        pageNo - 1
      }&caseStatus=${caseStatus}&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${
        dropFilter.value
      }`;
      Axios.post(worker_url)
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getVerificationList(perPage, 1);
  }, [
    filterUrl,
    sortUrl,
    sortDirectionUrl,
    localStorage.getItem("roleType"),
    assignFilterString,
    dropFilter,
    tab,
  ]);

  const columns = tab.open
    ? verificationOpenColumn
    : tab.picked
    ? verificationPickedColumn
    : tab.completed
    ? verificationCompletedColumn
    : verificationReturnedColumn;

  const handlePageChange = (page) => {
    getVerificationList(perPage, page);
    setCurrentPage(page);
  };

  const handleActions = (event) => {
    let val = event.target.value;
    if (
      event.target.value == 1 ||
      event.target.value == 3 ||
      event.target.value == 8 ||
      event.target.value == 9
    ) {
      setOpenAssignModal(true);
      if (val === 1) {
        setActionToPass("ASSIGN_TO_VERIFIER");
      } else if (val === 3 || val === 8 || val === 9) {
        setActionToPass("RE_ASSIGN_TO_VERIFIER");
      }
    } else {
      let reqBody = {
        casesId: [...caseIds],
        pincode: null,
        verifeirAssignCountModels: [],
      };
      let action = "";
      if (event.target.value === 2 || event.target.value === 5) {
        action = "MARK_RETURNED";
      }
      if (event.target.value === 4) {
        action = "MARK_COMPLETED";
      }
      if (event.target.value === 6) {
        action = "MARK_GREEN";
      }
      if (event.target.value === 7) {
        action = "MARK_RED";
      }
      Axios.post(
        `${UrlLinks.baseUrl}${
          UrlLinks.assignVerifier.assign
        }?action=${action}&insertedBy=${localStorage.getItem("userID")}`,
        reqBody
      )
        .then(function (response) {
          showToast("success", `Status marked successfully`, action);
          setCaseIds([]);
          setPincodeSelected([]);
          getVerificationList(perPage, 1);
        })
        .catch(function (error) {});
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      docListLoader,
      downloadDocError,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getVerificationList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisable(disableButton);
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameV2 zIndex1080 header"
                      : column.render("Header") === "Profile Completion"
                      ? ""
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header width25"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200"
                      : "header"
                  }
                >
                  <span
                    style={
                      column.render("Header") === "addressGoogle" ||
                      column.render("Header") === "documentsPending"
                        ? { width: "250px" }
                        : {}
                    }
                  >
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    tab.open
                      ? row.original.openRedFlag === 1
                        ? "criminalRecord"
                        : ""
                      : tab.picked
                      ? row.original.pickedRedFlag === 1
                        ? "criminalRecord"
                        : ""
                      : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          tab.open
                            ? row.original.openRedFlag === 1
                              ? "criminalRecord"
                              : ""
                            : tab.picked
                            ? row.original.pickedRedFlag === 1
                              ? "criminalRecord"
                              : ""
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  const handleTab = (event, name) => {
    // RESET filters except search bar
    resetFiltersWithoutSearch();
    //////////////////////////////////
    if (name === 1) {
      setTab({
        ...filters,
        open: true,
        picked: false,
        completed: false,
        returned: false,
      });
      setCaseIds([]);
    } else if (name === 2) {
      setTab({
        ...filters,
        open: false,
        picked: true,
        completed: false,
        returned: false,
      });
      setCaseIds([]);
    } else if (name === 3) {
      setTab({
        ...filters,
        open: false,
        picked: false,
        completed: true,
        returned: false,
      });
      setCaseIds([]);
    } else if (name === 4) {
      setTab({
        ...filters,
        open: false,
        picked: false,
        completed: false,
        returned: true,
      });
      setCaseIds([]);
    }
    setCurrentPage(1);
  };

  const handleUpload = () => {
    setOpenOnboardingModalV2(true);
  };

  return (
    <>
      <Styles>
        {openAssignModal && (
          <AssignToVerifierModal
            pincodeToAssign={pincodeSelected[0].pincode}
            caseIds={caseIds}
            setPincodeSelected={setPincodeSelected}
            setCaseIds={setCaseIds}
            action={actionToPass}
            getVerificationList={getVerificationList}
            openAssignModal={openAssignModal}
            setOpenAssignModal={setOpenAssignModal}
          />
        )}
        <Grid container className="padding2percent">
          <Grid
            item
            xs={12}
            className={`flexDisplay ${styles.headerContainer}`}
          >
            <span className="fontSize24 fontWeightBold marginRight20">
              Verification cases
            </span>
            <button
              className={styles.greyOutlinedButton}
              style={{ padding: "10px 16px" }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              <img src={FilterIcon} alt="filter-icon" />
              <span>Other Filters</span>
            </button>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${styles.searchContainer} flexDisplay alignItemsFlexStart`}
          >
            <Grid
              item
              xs={7}
              className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
            >
              <TextField
                className={`${classes.root} marginLeft0`}
                placeholder={"Search by Case ID / Case Pin code"}
                fullWidth
                value={searchBy}
                onChange={(event) => setSearchBy(event.target.value)}
                variant="outlined"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                InputProps={{
                  className: classes.input,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={styles.searchButtonDiv}>
                <IconButton
                  // className="filterCLoseButton"
                  className={`${styles.closeButton} ${
                    searchBy ? "" : styles.noVisibility
                  }`}
                  onClick={handleSearchClose}
                >
                  <CloseSharpIcon />
                </IconButton>
                <button onClick={handleSearch} className={styles.searchButton}>
                  Search
                </button>
              </div>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                className="dataButton"
                style={{ marginTop: "7px" }}
                onClick={handleOpenExportModal}
                disabled={allRecordLoading}
              >
                Export to Excel
              </Button>
              {allRecordLoading ? (
                <span style={{ marginRight: "5px" }}>
                  Please wait...it might take few minutes
                </span>
              ) : allRecord.length > 0 ? (
                <CSVLink
                  data={allRecord}
                  filename="Verification Cases Data.csv"
                  className="btn btn-primary blueColor downloadExcelButton"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    marginRight: "20px",
                    fontWeight: "500",
                  }}
                >
                  Download now
                </CSVLink>
              ) : null}
            </Grid>
          </Grid>
          {!searchError ? (
            <div className="searchInstruction">
              Use comma or space as delimiter to search multiple Case ID/Case
              PIN code. Max limit 100.
            </div>
          ) : (
            <div
              className="searchInstructionError"
              style={{ display: "flex", margin: "6px" }}
            >
              <WarningIcon></WarningIcon> &nbsp;
              {` Max limit reached. Search 100 pin max.`}
            </div>
          )}
          {
            <Grid container item xs={12} className={`flexDisplay paddingTop16`}>
              {
                <Grid item xs={8}>
                  <Button
                    onClick={(event) => {
                      if (!tab.open) {
                        handleTab(event, 1);
                        setCaseIds([]);
                        setPincodeSelected([]);
                      }
                    }}
                    className={`statusButtons marginRight20 ${
                      tab.open ? "blueColorBorder" : "greyColorBorder"
                    }`}
                  >
                    Open
                  </Button>
                  <Button
                    onClick={(event) => {
                      if (!tab.picked) {
                        handleTab(event, 2);
                        setCaseIds([]);
                        setPincodeSelected([]);
                      }
                    }}
                    className={`statusButtons marginRight20 ${
                      tab.picked ? "blueColorBorder" : "greyColorBorder"
                    }`}
                  >
                    Picked
                  </Button>
                  <Button
                    onClick={(event) => {
                      if (!tab.completed) {
                        handleTab(event, 3);
                        setCaseIds([]);
                        setPincodeSelected([]);
                      }
                    }}
                    className={`statusButtons marginRight20 ${
                      tab.completed ? "blueColorBorder" : "greyColorBorder"
                    }`}
                  >
                    Completed
                  </Button>
                  <Button
                    onClick={(event) => {
                      if (!tab.returned) {
                        handleTab(event, 4);
                        setCaseIds([]);
                        setPincodeSelected([]);
                      }
                    }}
                    className={`statusButtons marginRight20 ${
                      tab.returned ? "blueColorBorder" : "greyColorBorder"
                    }`}
                  >
                    Returned
                  </Button>
                </Grid>
              }
              <Grid item xs={4} container className={styles.actionContainer}>
                {tab.open && (
                  <FormControl
                    variant="outlined"
                    className={styles.actionBar}
                    fullWidth
                  >
                    <Select
                      id="actions"
                      value={actions}
                      style={{ marginLeft: "", marginTop: "7px" }}
                      classes={{ outlined: styles.actionSelect }}
                      onChange={handleActions}
                    >
                      <MenuItem value={0} disabled={true}>
                        {" "}
                        Actions
                      </MenuItem>
                      <MenuItem
                        value={1}
                        disabled={!isUniquePincode || caseIds.length === 0}
                      >
                        Assign to verifier
                      </MenuItem>
                      <MenuItem value={2} disabled={caseIds.length === 0}>
                        Return
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {tab.picked && (
                  <FormControl
                    variant="outlined"
                    className={styles.actionBar}
                    fullWidth
                  >
                    <Select
                      id="actions"
                      value={actions}
                      style={{ marginLeft: "", marginTop: "7px" }}
                      classes={{ outlined: styles.actionSelect }}
                      onChange={handleActions}
                    >
                      <MenuItem value={0} disabled={true}>
                        {" "}
                        Actions
                      </MenuItem>
                      <MenuItem
                        value={3}
                        disabled={!isUniquePincode || caseIds.length === 0}
                      >
                        Re- assign to verifier
                      </MenuItem>
                      <MenuItem value={4} disabled={caseIds.length === 0}>
                        Change to completed
                      </MenuItem>
                      <MenuItem value={5} disabled={caseIds.length === 0}>
                        Change to returned
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {tab.completed && (
                  <FormControl
                    variant="outlined"
                    className={styles.actionBar}
                    fullWidth
                  >
                    <Select
                      id="actions"
                      value={actions}
                      style={{ marginLeft: "", marginTop: "7px" }}
                      classes={{ outlined: styles.actionSelect }}
                      onChange={handleActions}
                    >
                      <MenuItem value={0} disabled={true}>
                        {" "}
                        Actions
                      </MenuItem>
                      <MenuItem value={6} disabled={caseIds.length === 0}>
                        Mark as green
                      </MenuItem>
                      <MenuItem value={7} disabled={caseIds.length === 0}>
                        Mark as red
                      </MenuItem>
                      <MenuItem
                        value={8}
                        disabled={
                          !isUniquePincode ||
                          caseIds.length === 0 ||
                          assignToVerifierEnable
                        }
                      >
                        Re- assign to verifier
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {tab.returned && (
                  <FormControl
                    variant="outlined"
                    className={styles.actionBar}
                    fullWidth
                  >
                    <Select
                      id="actions"
                      value={actions}
                      style={{ marginLeft: "", marginTop: "7px" }}
                      classes={{ outlined: styles.actionSelect }}
                      onChange={handleActions}
                    >
                      <MenuItem value={0} disabled={true}>
                        {" "}
                        Actions
                      </MenuItem>
                      <MenuItem
                        value={9}
                        disabled={!isUniquePincode || caseIds.length === 0}
                      >
                        Assign to verifier
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <Button
                  className={`${styles.uploadButton} statusButtons marginRight20 greyColorBorder`}
                  onClick={handleUpload}
                >
                  <img src={ExcelSvg} alt="excelSvg" />
                  &nbsp;&nbsp;Upload cases
                </Button>
              </Grid>
            </Grid>
          }
          {!isUniquePincode && (
            <Grid container item xs={12} className={`flexDisplay`}>
              <h3 className={styles.warningLine}>
                Please select cases belonging to single pincode only.
              </h3>
            </Grid>
          )}
          {loading ? (
            loader
          ) : data.length === 0 ? (
            <div className={styles.noRecord}>
              <img src={NoRecord} alt="noRecord" />
              <span>No records found</span>
            </div>
          ) : (
            <Grid
              item
              xs={12}
              className={isUniquePincode ? "paddingTop16" : ""}
            >
              {table}
              <Pagination
                rowsperpage={perPage}
                pageNo={currentPage}
                totalElements={totalRows}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </Grid>
          )}
          <Drawer
            anchor="right"
            open={openFilterDrawer}
            onClose={() => {
              setOpenFilterDrawer(false);
              setClientName(multiSelectId.clientName);
            }}
          >
            <FilterDrawer
              handleClose={() => setOpenFilterDrawer(false)}
              filters={filters}
              setFilters={setFilters}
              applyFilters={ApplyFilters}
              resetFilters={resetFilters}
              clientName={clientName}
              setClientName={setClientName}
              multiSelectId={multiSelectId}
              setMultiSelectId={setMultiSelectId}
            ></FilterDrawer>
          </Drawer>
        </Grid>
      </Styles>
    </>
  );
};

export default WorkerData;
