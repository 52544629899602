import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from "react-csv";
import Download from "../../assets/Download.svg";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Styles from "./Dialogs.module.scss";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openResponseModal,
    setOpenResponseModal,
    setOpenOnboardingModal,
    responseData,
    invalidData,
    uniqueKey,
    fileName,
  } = props;

  /** accessing states from redux store **/

  const { driveDb } = useSelector((state) => state);
  const { driveTab } = driveDb;

  const handleClose = () => {
    setOpenOnboardingModal(false);
    setOpenResponseModal(false);
  };

  //  function to create an excel sheet
  const handleExcelInvalidExport = async (data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    if (uniqueKey === "driveBulkUpdate") {
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }
  };

  //  function to handle excel export
  const handleOnInvalidExport = async () => {
    await handleExcelInvalidExport(invalidData);
  };

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openResponseModal}
      classes={{ paper: Styles.paperResponse }}
      className={Styles.responseModal}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenResponseModal(false)}
      >
        <Grid item xs={12}>
          <span className={Styles.summaryText}>Summary of uploaded data</span>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12} className="padding0">
          <span className={Styles.mainRecord}>
            {uniqueKey === "applicationDbBulkUpdate"
              ? "# Application status attempted to update : " +
                responseData.totalAttempted
              : uniqueKey === "createApplication"
              ? "# Job applications attempted to create : " +
                responseData["Total Applications Uploaded"]
              : uniqueKey === "uploadMettlAssessor"
              ? "# Workers attempted to create : " + responseData.totalAttempted
              : uniqueKey === "uploadOffboardingData"
              ? "Worker records attempted to upload : " +
                responseData["Total records attempted"]
              : uniqueKey === "driveBulkUpdate" && driveTab.driveDashboard
              ? "Drive records attempted to upload : " +
                responseData.totalAttempted
              : uniqueKey === "driveBulkUpdate" && driveTab.workerDashboard
              ? "Worker records attempted to upload : " +
                responseData.totalAttempted
              : uniqueKey === "addShift"
              ? "# Records attempted to add : " + responseData.totalAttempted
              : "# Lead records attempted to update : " +
                responseData.totalAttempted}
          </span>
        </Grid>
        {responseData && (
          <Grid container className="padding0 marginTop16">
            <Grid
              item
              xs={12}
              className="padding0 marginTop16 flexDisplay justifyContentCenter"
            >
              <table className={Styles.tableContainer}>
                <tr>
                  <td>
                    {uniqueKey === "applicationDbBulkUpdate"
                      ? `# Application status uploaded`
                      : uniqueKey === "createApplication"
                      ? "# Applications created"
                      : uniqueKey === "uploadMettlAssessor"
                      ? "# Workers created"
                      : uniqueKey === "uploadOffboardingData"
                      ? "# Workers uploaded"
                      : uniqueKey === "driveBulkUpdate" &&
                        driveTab.workerDashboard
                      ? "# Workers uploaded"
                      : uniqueKey === "driveBulkUpdate" &&
                        driveTab.driveDashboard
                      ? "# Data uploaded"
                      : uniqueKey === "addShift"
                      ? "# Records uploaded"
                      : "# Leads uploaded"}
                  </td>
                  <td>
                    {uniqueKey === "createApplication"
                      ? responseData["Job Applications Uploaded"]
                      : uniqueKey === "uploadOffboardingData"
                      ? responseData["No. of workers offboarded"]
                      : responseData.totalUploaded}
                  </td>
                </tr>
                {invalidData && (
                  <tr>
                    <td># Invalid data records</td>
                    <td>{invalidData.length}</td>
                  </tr>
                )}
              </table>
            </Grid>
            <div>
              <div>
                {invalidData && (
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    {uniqueKey !== "driveBulkUpdate" ? (
                      <CSVLink
                        data={invalidData}
                        filename={`${fileName}.csv`}
                        className="blueColor downloadExcelButton"
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                          marginTop: "20px",
                          fontWeight: "500",
                        }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          Download invalid data
                        </span>
                        <img src={Download} alt="download" />
                      </CSVLink>
                    ) : (
                      <Button
                        onClick={handleOnInvalidExport}
                        className="blueColor downloadExcelButton"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                          fontSize: "16px",
                          textTransform: "none",
                        }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          Download invalid data
                        </span>
                        <img src={Download} alt="download" />
                      </Button>
                    )}
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {responseData && (
          <Button
            variant="contained"
            onClick={
              uniqueKey === "statusUpdate"
                ? () => setOpenResponseModal(false)
                : handleClose
            }
            className={"blueButtonContained"}
          >
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  return dialogData;
}
