import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import { dateFormat, createWorkerStatus } from "../../services/CommonServices";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import ScaleLoader from "react-spinners/ScaleLoader";
import LastSentMsgs from "../WorkerData/components/LastSentMsgs";
import { AuthContext } from "../../services/AuthProvider";
import SendMsg from "./components/SendMsg";
import pendingIcon from "./Pending_icon1.svg";
import verifyIcon from "./Verified_icon.svg";
import mismatchIcon from "./Mismatch_icon.svg";
import failedIcon from "./Failed_icon.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import "./indeterminateCheckbox.css";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    width: 95vw;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {},
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const BankDetailsList = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [openSendMsg, setOpenSendMsg] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("");
  const [disablePayments, setDisablePayments] = useState(true);
  const [filterUrl, setFilterUrl] = useState("");
  const [isRecruiterData, setIsRecruiterData] = useState("");
  const [filters, setFilters] = useState({
    searchBy: "",
    all: true,
    verified: false,
    mismatch: false,
    mismatchConfirmed: false,
    failed: false,
    inprocess: false,
    invoiceGen: "-1",
    notfilled: false,
  });
  const [tab, setTab] = useState({
    workerDataTab: true,
    recruiterDataTab: false,
  });
  const { showToast } = useContext(AuthContext);

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
  };

  // Penny testing
  const handlePennyTestingStatuses = (event, name) => {
    if (name === "all") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: false,
          inprocess: false,
          mismatchconfirmed: false,
          mismatch: false,
          verified: false,
          all: true,
          notfilled: false,
        });
      }
    } else if (name === "inprocess") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: false,
          inprocess: true,
          mismatchconfirmed: false,
          mismatch: false,
          verified: false,
          all: false,
          notfilled: false,
        });
      }
    } else if (name === "verified") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: false,
          inprocess: false,
          mismatchconfirmed: false,
          mismatch: false,
          verified: true,
          all: false,
          notfilled: false,
        });
      }
    } else if (name === "mismatch") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: false,
          inprocess: false,
          mismatchconfirmed: false,
          mismatch: true,
          verified: false,
          all: false,
          notfilled: false,
        });
      }
    } else if (name === "mismatchconfirmed") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: false,
          inprocess: false,
          mismatchconfirmed: true,
          mismatch: false,
          verified: false,
          all: false,
          notfilled: false,
        });
      }
    } else if (name === "failed") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: true,
          inprocess: false,
          mismatchconfirmed: false,
          mismatch: false,
          verified: false,
          all: false,
          notfilled: false,
        });
      }
    } else if (name === "notfilled") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          failed: false,
          inprocess: false,
          mismatchconfirmed: false,
          mismatch: false,
          verified: false,
          all: false,
          notfilled: true,
        });
      }
    }
  };

  const resetFiltersWithoutSearch = () => {
    const filterList = {
      source: "",
      searchBy: filters.searchBy,
      zone: "",
      facilityState: "",
      facilityName: "",
      partnerId: "",
      facilityCity: "",
      workFor: "",
      workerStatus: "",
      psf: "",
      callStatus: "",
      interviewStatus: "",
      recruiterId: "",
      trainingUpdate: "",
      preferedHub: "",
      appLead: "",
      interviewDate: [null, null],
      firstEarningDate: [null, null],
      leadAddedDate: [null, null],
      bankDetails: "",
      leadCity: "",
      leadSource: "",
      lastEarning: "",
      appStatus: "",
      clientEarningStatus: "",
      clientStatus: "",
      RecruiterAssignedBetween: [null, null],
      idGenerationDate: [null, null],
      IsLmaStatus: "",
      bgvStatus: "",
      lastAppliedBy: "",
    };
    setFilters(filterList);
    setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "");
    setCurrentPage(1);
    return filterList;
  };

  const ApplyFilters = () => {
    let url = "";
    setAllRecord([]);

    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["notFilled"]) {
      url = `${url}&bank_details=0`;
    }
    if (filters["inprocess"]) {
      url = `${url}&pennyTestingStatus=2`;
    }
    if (filters["mismatchconfirmed"]) {
      url = `${url}&pennyTestingStatus=5`;
    }
    if (filters["mismatch"]) {
      url = `${url}&pennyTestingStatus=4`;
    }
    if (filters["verified"]) {
      url = `${url}&pennyTestingStatus=1`;
    }
    if (filters["failed"]) {
      url = `${url}&pennyTestingStatus=3`;
    }
    if (filters["notfilled"]) {
      url = `${url}&bank_details=0`;
    }
    if (filters["invoiceGen"] === "0" || filters["invoiceGen"] === "1") {
      url = `${url}&invoiceGen=${filters["invoiceGen"]}`;
    }
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleInvoiceGen = (e) => {
    setFilters({ ...filters, invoiceGen: e.target.value });
  };

  useEffect(() => {
    ApplyFilters();
  }, [filters]);

  //columns list
  const handleSendMsg = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1]) {
        idList += data[parseFloat(id[0])].userId + ",";
      }
      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    setSelectedUserIds(idList);
    setOpenSendMsg(!openSendMsg);
  };

  const workerDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Client id(s)",
        accessor: "casperId",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el) => {
                return (
                  el.client_id && (
                    <div>
                      <b>{el.clientName}: </b>
                      {el.client_id}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return row.row.original.verification !== 4 &&
            row.row.original.bankDetailsFilled === "Yes" ? (
            <span className="nameLink">{row.row.original.name}</span>
          ) : (
            <span>{row.row.original.name}</span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Penny Testing Status",
        Cell: (row) => {
          return makePennyStatus(
            row.row.original.pennyTestingStatus,
            row.row.original
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last SMS sent",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact Number",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Clients",
        accessor: "clients",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Details Filled",
        accessor: "bankDetailsFilled",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verification status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verified by",
        accessor: "verifiedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verified on",
        accessor: "verifiedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Worker added on",
        accessor: "workerAddedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last updated on",
        accessor: "lastUpdatedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const recruiterDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Recruiter id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return row.row.original.verification !== 4 &&
            row.row.original.bankDetailsFilled === "Yes" ? (
            <span className="nameLink">{row.row.original.name}</span>
          ) : (
            <span>{row.row.original.name}</span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Penny Testing Status",
        Cell: (row) => {
          return makePennyStatus(
            row.row.original.pennyTestingStatus,
            row.row.original
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last SMS sent",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact Number",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Details Filled",
        accessor: "bankDetailsFilled",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verification status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verified by",
        accessor: "verifiedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Verified on",
        accessor: "verifiedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last updated on",
        accessor: "lastUpdatedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const columns = tab.workerDataTab ? workerDataColumn : recruiterDataColumn;

  const createVerificationStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "To be Verified";
        break;
      case 1:
        text = "Verified";
        break;
      case 2:
        text = "Mismatch";
        break;
      case 3:
        text = "Mismatch confirmed";
        break;
      case 4:
        text = "Not filled";
        break;
      default:
        break;
    }
    return text;
  };

  const makePennyStatus = (status, row) => {
    if (status === null) {
      return "-";
    }
    switch (status) {
      case 0:
        return <div className={styles.pannyStatusDiv}>-</div>;
      case 1:
        return (
          <div className={styles.pannyStatusDiv}>
            <img width={"15px"} src={verifyIcon} alt=""></img>
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              {" "}
              Verified{" "}
            </div>{" "}
            <Link
              to={{
                pathname: `/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}`,
              }}
              target="_blank"
              className="nameLink"
            >
              {"View Details"}
            </Link>
          </div>
        );
      case 2:
        return (
          <div className={styles.pannyStatusDiv}>
            <img width={"15px"} src={pendingIcon} alt=""></img>
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              {" "}
              Pending{" "}
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.pannyStatusDiv}>
            <img width={"15px"} src={failedIcon} alt=""></img>
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              {" "}
              Failed{" "}
            </div>{" "}
            <Link
              to={{
                pathname: `/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}`,
              }}
              target="_blank"
              className="nameLink"
            >
              {"View Details"}
            </Link>
          </div>
        );
      case 4:
        return (
          <div className={styles.pannyStatusDiv}>
            <img width={"15px"} src={mismatchIcon} alt=""></img>
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              {" "}
              Mismatch{" "}
            </div>{" "}
            <Link
              to={{
                pathname: `/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}`,
              }}
              target="_blank"
              className="nameLink"
            >
              {"View Details"}
            </Link>
          </div>
        );
      case 5:
        return (
          <div className={styles.pannyStatusDiv}>
            <img width={"15px"} src={mismatchIcon} alt=""></img>
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              {" "}
              Mismatch Confirmed
            </div>{" "}
            <Link
              to={{
                pathname: `/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}`,
              }}
              target="_blank"
              className="nameLink"
            >
              {"View Details"}
            </Link>
          </div>
        );
      default:
        return (
          <div className={styles.pannyStatusDiv}>
            <Link
              to={{
                pathname: `/pennyTestingverification/${row.userId}/${row.verification}`,
              }}
              className="nameLink"
            >
              {"View Details"}
            </Link>
          </div>
        );
    }
  };

  const makePennyStatusForExport = (status, row) => {
    if (status === null) {
      return "-";
    }
    switch (status) {
      case 0:
        return "-";
      case 1:
        return "Verified";
      case 2:
        return " Pending  ";
      case 3:
        return " Failed";
      case 4:
        return "Mismatch";
      case 5:
        return "Mismatch Confirmed";
      default:
        return "";
    }
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      extractedData.push({
        facilityData: el.clientList || [],
        casperId: el.casperId || "",
        okaygoId: el.okaygoId || "-",
        swiggyId: el.swiggyId || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        status: createWorkerStatus(el.workerStatus),
        pennyTestingStatus:
          el.pennyTestingStatus !== null ? el.pennyTestingStatus : 0,
        workerStatus: el.workerStatus,
        contactNo: el.phoneNumber || "",
        clients: makeMultiClient(el.clientList),
        aadharNumber: el.aadhaarNumber || "",
        bankDetailsFilled:
          el.bankDetails === "0" ? "No" : el.bankDetails === "1" ? "Yes" : "",
        verificationStatus: createVerificationStatus(el.verification),
        verifiedBy: el.verifiedBy || "",
        verifiedOn: dateFormat(el.verifiedOn),
        userId: el.userId,
        verification: el.verification,
        workerAddedOn: el.workerInsertedOn
          ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        lastUpdatedOn: el.lastUpdatedOn
          ? moment(el.lastUpdatedOn).format("DD/MM/YYYY hh:mm a")
          : "",
      });
      return true;
    });
    return extractedData;
  };

  const makeMultiClient = (clients) => {
    let str = " ";
    clients.map((el) => {
      str += el.clientName + ",";
      return true;
    });
    return str.substring(0, str.length - 1);
  };

  const handleClientList = (el) => {
    let clientData = {};
    el.clientList.map((e) => {
      if (e.clientName) {
        clientData = {
          ...clientData,
          [`${e.clientName} Id`]: e.client_id || "",
        };
      }
      return true;
    });
    return clientData;
  };

  //Function to create All record export data
  const makeAllRecordData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      let temp;
      if (tab.workerDataTab) {
        temp = {
          okaygoId: el.okaygoId || "",
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
          pennyTestingStatus: el.pennyTestingStatus
            ? makePennyStatusForExport(el.pennyTestingStatus)
            : "-",
          contactNo: el.phoneNumber || "",
          clients: makeMultiClient(el.clientList),
          bankDetailsFilled:
            el.bankDetails === "0" ? "No" : el.bankDetails === "1" ? "Yes" : "",
          verificationStatus: createVerificationStatus(el.verification),
          verifiedBy: el.verifiedBy || "",
          verifiedOn: dateFormat(el.verifiedOn),
          workerAddedOn: el.workerInsertedOn
            ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
            : "",
          lastUpdatedOn: el.lastUpdatedOn
            ? moment(el.lastUpdatedOn).format("DD/MM/YYYY hh:mm a")
            : "",
          ...handleClientList(el),
        };
      } else {
        temp = {
          recruiterId: el.okaygoId || "",
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
          pennyTestingStatus: el.pennyTestingStatus
            ? makePennyStatusForExport(el.pennyTestingStatus)
            : "-",
          contactNo: el.phoneNumber || "",
          bankDetailsFilled:
            el.bankDetails === "0" ? "No" : el.bankDetails === "1" ? "Yes" : "",
          verificationStatus: createVerificationStatus(el.verification),
          verifiedBy: el.verifiedBy || "",
          verifiedOn: dateFormat(el.verifiedOn),
          lastUpdatedOn: el.lastUpdatedOn
            ? moment(el.lastUpdatedOn).format("DD/MM/YYYY hh:mm a")
            : "",
        };
      }
      extractedData.push(temp);
      return true;
    });
    return extractedData;
  };

  const updateMyData = () => {
    getBankVerificationDetailsList(perPage, currentPage);
  };

  const createAllRecordExcel = () => {
    setAllRecordLoading(true);
    let worker_url = `${urlLinks.baseUrl}${
      urlLinks.bankVerification.listing
    }?page_no=${currentPage - 1}&rows=${10000}${filterUrl}&exportExcel=1`;
    let recruiter_url = `${urlLinks.baseUrl}${
      urlLinks.bankVerification.listing
    }?page_no=${
      currentPage - 1
    }&rows=${10000}${filterUrl}${isRecruiterData}&exportExcel=1`;
    Axios.get(tab.workerDataTab ? worker_url : recruiter_url).then(
      (response) => {
        if (response) {
          setAllRecord(makeAllRecordData(response.data.response));
        }
      }
    );
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getBankVerificationDetailsList = (rows, pageNo) => {
    swal.close();
    setLoading(true);
    let worker_url = `${urlLinks.baseUrl}${
      urlLinks.bankVerification.listing
    }?page_no=${pageNo - 1}&rows=${rows}${filterUrl}`;
    let recruiter_url = `${urlLinks.baseUrl}${
      urlLinks.bankVerification.listing
    }?page_no=${pageNo - 1}&rows=${rows}${filterUrl}${isRecruiterData}`;
    Axios.get(tab.workerDataTab ? worker_url : recruiter_url)
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBankVerificationDetailsList(perPage, 1);
    tab.workerDataTab
      ? localStorage.setItem("location", "worker")
      : localStorage.setItem("location", "recruiter");
  }, [filterUrl, tab, localStorage.getItem("roleType")]);

  const handlePageChange = (page) => {
    getBankVerificationDetailsList(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getBankVerificationDetailsList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={
                        column.render("Header") === "Worker Id"
                          ? "stickWorkerIdnewMatch zIndex1080 header"
                          : column.render("Header") === "Name"
                          ? "stickWorkerNameV2 zIndex1080 header"
                          : column.id === "selection"
                          ? "stickCheckbox zIndex1080 header"
                          : "header"
                      }
                    >
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  const handleTab = (event, name) => {
    // RESET filters except search bar
    resetFiltersWithoutSearch();
    //////////////////////////////////

    if (name === "1") {
      setTab({ ...tab, workerDataTab: true, recruiterDataTab: false });
      setIsRecruiterData("");
      setCurrentPage(1);
    } else if (name === "2") {
      setTab({ ...tab, workerDataTab: false, recruiterDataTab: true });
      setIsRecruiterData("&isRecruiter=1");
      setCurrentPage(1);
    }
  };

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12} className="flexDisplay justifyFlexStart">
          <span className="fontSize24 fontWeightBold marginRight20">
            Bank Details Verification
          </span>
          <Button
            onClick={(event) => {
              if (tab.recruiterDataTab === true) {
                handleTab(event, "1");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Worker
          </Button>
          <Button
            onClick={(event) => {
              if (tab.workerDataTab === true) {
                handleTab(event, "2");
              }
            }}
            className={`statusButtons ${
              tab.recruiterDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Recruiter
          </Button>
        </Grid>
        <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
          <Grid
            item
            xs={8}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder={
                tab.recruiterDataTab
                  ? "Phone No / Recruiter ID"
                  : "Enter Aadhar No / Phone No / Casper ID/ OkayGo ID"
              }
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className="flexDisplay padding0 justifySpaceBetween"
          ></Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16 alignItemsFlexStart"
        >
          {
            <Grid item xs={6} className=" alignItemsFlexStart">
              <Button
                variant="contained"
                className="dataButton"
                onClick={createAllRecordExcel}
                disabled={allRecordLoading}
              >
                Export to Excel
              </Button>
              {allRecordLoading ? (
                <span style={{ marginLeft: "20px" }}>
                  Please wait ... it might take few minutes.{" "}
                </span>
              ) : allRecord.length > 0 ? (
                <CSVLink
                  data={allRecord}
                  filename={
                    isRecruiterData === ""
                      ? `Worker Data.csv`
                      : `Recruiter Data.csv`
                  }
                  className="btn btn-primary blueColor downloadExcelButton"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    marginLeft: "20px",
                    fontWeight: "500",
                  }}
                >
                  Download now
                </CSVLink>
              ) : null}
              {tab.workerDataTab
                ? (localStorage.getItem("roleType") === "2" ||
                    localStorage.getItem("roleType") === "6" ||
                    localStorage.getItem("roleType") === "31") && (
                    <Button
                      style={{ marginLeft: "12px" }}
                      variant="contained"
                      className="dataButton"
                      onClick={() => handleSendMsg()}
                      disabled={disablePayments}
                    >
                      Send Message
                    </Button>
                  )
                : ""}
            </Grid>
          }
        </Grid>
        {!loading && (
          <Grid
            item
            xs={12}
            className="flexDisplay paddingTop16 justifyFlexStart"
          >
            {/* penny testing filter chip */}
            <Button
              onClick={(event) => handlePennyTestingStatuses(event, "all")}
              className={`statusButtons marginRight20 ${
                filters.all ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              All
            </Button>
            <Button
              onClick={(event) =>
                handlePennyTestingStatuses(event, "inprocess")
              }
              className={`statusButtons marginRight20 ${
                filters.inprocess ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Pending
            </Button>
            <Button
              onClick={(event) =>
                handlePennyTestingStatuses(event, "notfilled")
              }
              className={`statusButtons marginRight20 ${
                filters.notfilled ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Not Filled
            </Button>
            <Button
              onClick={(event) => handlePennyTestingStatuses(event, "verified")}
              className={`statusButtons marginRight20 ${
                filters.verified ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Verified
            </Button>
            <Button
              onClick={(event) => handlePennyTestingStatuses(event, "mismatch")}
              className={`statusButtons marginRight20 ${
                filters.mismatch ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Mismatch
            </Button>
            <Button
              onClick={(event) =>
                handlePennyTestingStatuses(event, "mismatchconfirmed")
              }
              className={`statusButtons marginRight20 ${
                filters.mismatchconfirmed
                  ? "blueColorBorder"
                  : "greyColorBorder"
              }`}
            >
              Mismatch Confirmed
            </Button>
            <Button
              onClick={(event) => handlePennyTestingStatuses(event, "failed")}
              className={`statusButtons ${
                filters.failed ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Failed
            </Button>
            <FormControl
              variant="outlined"
              className={styles.invoiceGeneratedDropdown}
            >
              <InputLabel
                htmlFor="invoice-generated"
                style={{ marginLeft: "30px" }}
              >
                Invoice Generated
              </InputLabel>
              <Select
                id="invoice-generated"
                IconComponent={ExpandMoreIcon}
                value={filters.invoiceGen}
                defaultValue={-1}
                onChange={handleInvoiceGen}
                className={classes.select}
                label="Invoice Generated"
                inputProps={{
                  name: "Invoice Generated",
                  id: "invoiceGenerated",
                }}
              >
                <MenuItem key={"All"} value="-1">
                  All
                </MenuItem>
                <MenuItem key={"Yes"} value="1">
                  Yes
                </MenuItem>
                <MenuItem key={"No"} value="0">
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openSendMsg && (
          <SendMsg
            filterUrl={filterUrl}
            open={openSendMsg}
            setOpen={setOpenSendMsg}
            userIds={selectedUserIds}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default BankDetailsList;
