import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Styles from "./Dialogs.module.scss";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../../assets/document.svg";
import { ScaleLoader } from "react-spinners";
import * as XLSX from "xlsx";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ResponseModal from "./ResponseModal";
import moment from "moment";
import Download from "../../assets/Download.svg";

const usestyles = makeStyles({
  paper: {
    minWidth: "579px",
    padding: "25px",
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openCasperOnboardingModal,
    setOpenCasperOnboardingModal,
    statusValues,
    showToast,
    updateMyData,
    createAllRecordExcel,
    title,
    uniqueKey,
    unapplied,
    url,
    fileName,
    templateButton,
  } = props;

  /** accessing states from redux store **/

  const { users, driveDb } = useSelector((state) => state);
  const { modalLoader } = users;
  const { driveTab } = driveDb;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const { setModalLoader } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = usestyles();
  const [excel, setExcel] = useState(null);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [responseData, setResponseData] = useState();
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [template, setTemplate] = useState("");

  const driveTemplate = "/Drive Template.xlsx";

  //  function to close modal
  const handleClose = () => {
    setName("");
    setOpenCasperOnboardingModal(false);
  };

  //  function to deselect a file
  const handleCloseFile = () => {
    setName("");
    setExcel(null);
  };

  //  function to validate excel columns in a below order
  const makeExportSelectedData = async (data) => {
    let list = [];
    switch (uniqueKey) {
      case "applicationDbBulkUpdate":
        data?.map((item) => {
          const newItem = {
            assign_id: item.assignId || "",
            user_id: item.userId || "",
            lead_id: item.leadId || "",
            full_name: item.name || "",
            phone_number: item.phoneNumber || "",
            job_id: item.jobId || "",
            job_role: item.jobRole || "",
            company_name: item.companyName || "",
            application_status:
              statusValues.find((id) => id.typeKey == item.applicationStatus)
                ?.typeValue || "",
          };
          if (!unapplied) {
            newItem.remarks = item.remarks || "";
          }
          list.push(newItem);
          return true;
        });
        break;

      case "driveBulkUpdate":
        data?.map((item) => {
          const driveNewItem = {
            Month: item.month || "",
            Zone: item.zone || "",
            State: item.state || "",
            "Exam City": item.examCity || "",
            "TC Code": item.tcCode || "",
            "Venue Name": item.venueName || "",
            "Exam Name": item.examName || "",
            "Exam Date": item.examDate
              ? moment(item.examDate).format("DD-MM-YYYY")
              : "",
            "Exam Timing": item.examTiming
              ? moment(item.examTiming, "HH:mm:ss").format("HH:mm:ss")
              : "",
            "Role Type": item.roleType || "",
            "Shift 1 Requirement Number": item.requirementNumberShift1 || "",
            "Shift 1 Fulfilled Number": item.fulfilledNumberShift1 || "",
            "Shift 2 Requirement Number": item.requirementNumberShift2 || "",
            "Shift 2 Fulfilled Number": item.fulfilledNumberShift2 || "",
            "Shift 3 Requirement Number": item.requirementNumberShift3 || "",
            "Shift 3 Fulfilled Number": item.fulfilledNumberShift3 || "",
            "Shift 4 Requirement Number": item.requirementNumberShift4 || "",
            "Shift 4 Fulfilled Number": item.fulfilledNumberShift4 || "",
            "Total no. shifts utilised": item.totalNoOfShiftsUtilized || "",
          };

          const workerNewItem = {
            Zone: item.zone || "",
            State: item.state || "",
            "City Name": item.cityName || "",
            "OkayGo ID": item.ogId || "",
            "Client Resource ID": item.clientResourceId || "",
            Name: item.name || "",
            Role: item.role || "",
            "Contact Number": item.contactNumber || "",
            "Email ID": item.emailId || "",
            "Name of the Agency/Vendor": item.agencyVendorName || "",
            "Workforce Father’s Name": item.workforceFathersName || "",
            "Workforce Mother’s Name": item.workforceMothersName || "",
            DOB: item.dob ? moment(item.dob).format("DD-MM-YYYY") : "",
            "Complete permanent address": item.completePermAddress || "",
            "Aadhaar Card": item.aadhaarCard || "",
            "Aadhaar Number": item.aadhaarNo || "",
            "Aadhaar Validation": item.aadhaarValidation || "",
            "PAN Card": item.panCard || "",
            "PAN Number": item.panNo || "",
            "PAN Validation": item.panValidation || "",
            "12th Pass Certificate": item.twelfthPassCertificate || "",
            "12th Validation": item.twelfthPassCertificateValidation || "",
            "10th Pass Certificate": item.tenthPassCertificate || "",
            "10th Validation": item.tenthPassCertificateValidation || "",
            "BGV Status": item.bgvStatus || "",
            "If Yes, BGV Date": item.bgvDate
              ? moment(item.bgvDate).format("DD-MM-YYYY")
              : "",
            "BGV Validation": item.bgvValidation || "",
          };
          list.push(driveTab.workerDashboard ? workerNewItem : driveNewItem);
          return true;
        });
        break;
      default:
        break;
    }

    return list;
  };

  //  function to create an excel sheet
  const handleExcelExport = async (data) => {
    // creating a workbook called wb
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    if (uniqueKey === "applicationDbBulkUpdate") {
      let data2 = [];
      statusValues.map((item) => {
        if (
          item.typeKey !== 1 &&
          item.typeKey !== 10 &&
          item.typeKey !== 12 &&
          item.typeKey !== 13 &&
          item.typeKey !== 16 &&
          item.typeKey !== 22
        ) {
          /* Keeping "Started working" as a column name */
          data2.push({ "Started working": item.typeValue });
        }
        return true;
      });

      // Create worksheet for data2
      const worksheet2 = XLSX.utils.json_to_sheet(data2);
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet2,
        "allowed values for status"
      );

      XLSX.writeFile(workbook, "EXPORT STATUS TEMPLATE.xlsx");
    } else if (uniqueKey === "driveBulkUpdate") {
      XLSX.writeFile(
        workbook,
        driveTab.workerDashboard
          ? "EXPORT INVIGILATOR TEMPLATE.xlsx"
          : "EXPORT DRIVE DB TEMPLATE.xlsx"
      );
    }
  };

  //  function to handle excel export
  const handleOnExport = async () => {
    setModalLoader(true);
    let asignIdResponse = await createAllRecordExcel();
    let listData = await makeExportSelectedData(asignIdResponse);
    await handleExcelExport(listData);
    setModalLoader(false);
  };

  //  function to handle browse files
  const handleBrowse = (event) => {
    const fileExtension = event.target.files[0]?.name?.split(".").slice(-1)[0];
    const file_Name = event.target.files[0]?.name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(file_Name);
      setExcel(event.target.files[0]);
    } else {
      setName("");
      setExcel(null);
    }
    setResponseData();
    event.target.value = null;
  };

  //  function to create an invalid excel sheet
  const createInvalidData = (records) => {
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        if (uniqueKey === "driveBulkUpdate") {
          if (driveTab.workerDashboard) {
            list.push({
              Zone: record.zone || "",
              State: record.state || "",
              "City Name": record.cityName || "",
              "OkayGo ID": record.ogId || "",
              "Client Resource ID": record.clientResourceId || "",
              Name: record.name || "",
              Role: record.role || "",
              "Contact Number": record.contactNumber || "",
              "Email ID": record.emailId || "",
              "Name of the Agency/Vendor": record.agencyVendorName || "",
              "Workforce Father’s Name": record.workforceFathersName || "",
              "Workforce Mother’s Name": record.workforceMothersName || "",
              DOB: record.dob ? moment(record.dob).format("DD-MM-YYYY") : "",
              "Complete permanent address": record.completePermAddress || "",
              "Aadhaar Card": record.aadhaarCard || "",
              "Aadhaar Number": record.aadhaarNo || "",
              "Aadhaar Validation": record.aadhaarValidation || "",
              "PAN Card": record.panCard || "",
              "PAN Number": record.panNo || "",
              "PAN Validation": record.panValidation || "",
              "12th Pass Certificate": record.twelfthPassCertificate || "",
              "12th Validation": record.twelfthPassCertificateValidation || "",
              "10th Pass Certificate": record.tenthPassCertificate || "",
              "10th Validation": record.tenthPassCertificateValidation || "",
              "BGV Status": record.bgvStatus || "",
              "If Yes, BGV Date": record.bgvDate
                ? moment(record.bgvDate).format("DD-MM-YYYY")
                : "",
              "BGV Validation": record.bgvValidation || "",
              "Reason for failure": record.errorString || "",
            });
          } else {
            list.push({
              Month: record.month || "",
              Zone: record.zone || "",
              State: record.state || "",
              "Exam City": record.examCity || "",
              "TC Code": record.tcCode || "",
              "Venue Name": record.venueName || "",
              "Exam Name": record.examName || "",
              "Exam Date": record.examDate
                ? moment(record.examDate).format("DD-MM-YYYY")
                : "",
              "Exam Timing": record.examTiming || "",
              "Role Type": record.roleType || "",
              "Shift 1 Requirement Number":
                record.requirementNumberShift1 || "",
              "Shift 1 Fulfilled Number": record.fulfilledNumberShift1 || "",
              "Shift 2 Requirement Number":
                record.requirementNumberShift2 || "",
              "Shift 2 Fulfilled Number": record.fulfilledNumberShift2 || "",
              "Shift 3 Requirement Number":
                record.requirementNumberShift3 || "",
              "Shift 3 Fulfilled Number": record.fulfilledNumberShift3 || "",
              "Shift 4 Requirement Number":
                record.requirementNumberShift4 || "",
              "Shift 4 Fulfilled Number": record.fulfilledNumberShift4 || "",
              "Total no. shifts utilised": record.totalNoOfShiftsUtilized || "",
              "Reason for failure": record.errorString || "",
            });
          }
        } else if (uniqueKey === "applicationDbBulkUpdate") {
          list.push({
            assignId: record.assignId || "",
            userId: record.userId || "",
            leadId: record.leadId || "",
            fullName: record.fullName || "",
            phoneNumber: record.phoneNumber || "",
            jobId: record.jobId || "",
            jobRole: record.jobRole || "",
            companyName: record.companyName || "",
            applicationStatus: record.applicationStatus || "",
            "Reason for failure": record.reasonOfFailure || "",
          });
        }
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  //  function to handle upload data
  const handleUpload = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (uniqueKey === "driveBulkUpdate") {
          if (response.data) {
            setInvalidData(createInvalidData(response.data.errorRecords));
            const summary = {
              totalAttempted: response.data.totalCount,
              totalUploaded: response.data.success,
            };
            setResponseData(summary);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        } else if (uniqueKey === "applicationDbBulkUpdate") {
          if (response.data.response) {
            setInvalidData(
              createInvalidData(response.data.response.invalidExcel)
            );
            const summary = {
              totalAttempted: response.data.response.totalAttempted,
              totalUploaded: response.data.response.totalUploaded,
            };
            setResponseData(summary);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        }
      })
      .catch((error) => {
        console.log("error", { error });
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  useEffect(() => {
    if (uniqueKey === "driveBulkUpdate") {
      setTemplate(driveTemplate);
    }
  }, []);

  const loaderBar = (
    <div>
      <ScaleLoader height={20} width={10} color={"#1c75bc"} />
    </div>
  );

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openCasperOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={Styles.onboardingDialogTitle}
        onClose={handleClose}
      >
        <span>{title}</span>
        {uniqueKey === "driveBulkUpdate" && driveTab.driveDashboard && (
          <a
            style={{ paddingRight: "2.5rem", paddingLeft: "10px" }}
            href={template}
            download
            target="_blank"
            className={Styles.downloadTemplate}
          >
            <span>{templateButton}</span>
            <img
              style={{ paddingLeft: "0.5rem" }}
              src={Download}
              alt="download"
            />
          </a>
        )}
      </DialogTitle>
      <DialogContent className={Styles.onboardingDialogContent} dividers>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "600",
          }}
        >
          <div>Export data for bulk update</div>
          <div style={{ marginTop: "10px" }}>
            <Button variant="outlined" onClick={handleOnExport}>
              Export
            </Button>
            {modalLoader ? loaderBar : null}
          </div>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "600",
            marginTop: "20px",
          }}
        >
          <div>Browse file for bulk update</div>
          <div style={{ marginTop: "10px" }}>
            <input
              style={{ display: "none", marginLeft: "20px" }}
              id="contained-button-file"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleBrowse}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                component="span"
                startIcon={<img src={document} alt={"upload"} />}
              >
                Browse File
              </Button>
              <div className={`fontSize13`}>
                only .xls and .xlsx are allowed
              </div>
            </label>
          </div>
          <Grid item xs={12}>
            {name && (
              <span className={Styles.fileName}>
                {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
                <span>
                  <IconButton
                    aria-label="close"
                    style={{ color: "white", paddingRight: "0" }}
                    onClick={handleCloseFile}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={`${Styles.onboardingButton}`}>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleUpload}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          setOpenOnboardingModal={setOpenCasperOnboardingModal}
          responseData={responseData}
          invalidData={invalidData}
          uniqueKey={uniqueKey}
          fileName={fileName}
        />
      )}
    </Dialog>
  );

  return dialogData;
}
