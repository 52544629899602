/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function AutoSelect(props) {
  const { options, filters, setFilters, id, multipleFlag, placeholder}= props
  const classes = useStyles();
  // console.log(id)
  
  return (
    <Autocomplete
      multiple = {multipleFlag===false ? false: true}
      id={id}
      value={filters[id]}
      onChange={(event, value) => setFilters({...filters, [id]: value})}
      style={{ width: 250 }}
      options={options}
      classes={{
        option: classes.option,
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <React.Fragment>
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}
