import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "./index.module.scss";
import AutoSelectFilter from "./FilterComponents/AutoSelectFilter";
import AutoCompleteFilter from "./FilterComponents/AutoCompleteFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../UrlLinks";
import DateFilter from "../../Utils/FilterComponents/DateFilter";
import "./indeterminateCheckbox.css";

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    applyFilters,
    filters,
    setFilters,
    clientName,
    setClientName,
    multiSelectId,
    setMultiSelectId,
  } = props;
  const [assignedVerifiers, setAssignedVerifiers] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [clientNameData, setClientNameData] = useState([]);
  const [pincodeData, setPincodeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCancelFilters = () => {
    setClientName(multiSelectId.clientName);
    handleClose();
  };

  const handleApplyFilters = () => {
    setMultiSelectId({
      clientName: clientName,
    });
    applyFilters();
    handleClose();
  };

  const statusSince = [
    {
      id: 8,
      name: "Last 8 Hour",
    },
    {
      id: 16,
      name: "Last 16 Hour",
    },
    {
      id: 24,
      name: "Last 24 Hour",
    },
    {
      id: 48,
      name: "Last 48 Hour",
    },
  ];

  const verificationStatus = [
    {
      id: 1,
      name: "Red",
    },
    {
      id: 2,
      name: "Green",
    },
  ];

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const makeAssignedVerifierData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.userId,
        name: el.firstName + " " + el.lastName,
        phoneNumber: el.phoneNumber,
      });
      return true;
    });
    return list;
  };

  const makeCityData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        name: el.district_name ? el.district_name : "",
      });
      return true;
    });
    return list;
  };

  const makeClientNameData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        name: el.clientName,
      });
      return true;
    });
    return list;
  };

  const makePincodeData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        name: el.pincode,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    if (assignedVerifiers.length > 0) {
      setIsLoading(false);
    }
  }, [assignedVerifiers]);

  useEffect(() => {
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.verificationCases.assingedVerifier}`
    ).then((response) => {
      setAssignedVerifiers(makeAssignedVerifierData(response.data.response));
    });

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.verificationCases.getCasesPincodeAndCity}city=1`
    ).then((response) => {
      setCityData(makeCityData(response.data.response));
    });

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.verificationCases.getCasesPincodeAndCity}clientName=1`
    ).then((response) => {
      setClientNameData(makeClientNameData(response.data.response));
    });

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.verificationCases.getCasesPincodeAndCity}pincode=1`
    ).then((response) => {
      setPincodeData(makePincodeData(response.data.response));
    });
  }, []);

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={style.FilterContainer}
    >
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div className={style.flexBaseLine}>
          <Button
            onClick={handleCancelFilters}
            className={style.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid
        container
        className={style.filtersContainer}
        style={{ paddingBottom: "0" }}
      >
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Client</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="clientName"
            options={clientNameData}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Assigned verifier</span>
          <AutoCompleteFilter
            filters={filters}
            setFilters={setFilters}
            id="verifiers"
            options={assignedVerifiers}
            data={clientName}
            setData={setClientName}
          />
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid
          item
          xs={12}
          style={{ marginTop: "30px" }}
          className={`${style.singleFilterContainer} ${
            filters.statusByDate[0] !== null || filters.statusByDate[1] !== null
              ? style.isDisabled
              : ""
          }`}
        >
          <span className={style.filterTitle}>Status since</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="statusSince"
            options={statusSince}
            placeholder="Type last x hours"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={`${style.singleFilterContainer} ${
            filters.statusSince === "" || filters.statusSince === null
              ? ""
              : style.isDisabled
          }`}
        >
          <span className={style.filterTitle}>Status by date</span>
          <Grid item xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="statusByDate"
              style={{ padding: 0 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Verification status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="verificationStatus"
            options={verificationStatus}
          />
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid
          item
          xs={12}
          className={`${style.singleFilterContainer}`}
          style={{ marginTop: "30px" }}
        >
          <span className={style.filterTitle}>Case city</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="city"
            options={cityData}
            placeholder="Type city"
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Case pincode</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="pincode"
            options={pincodeData}
            placeholder="Type pincode"
          />
        </Grid>
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            className={style.PrimaryColorButton}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
