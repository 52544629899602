import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./ResponseModal";
import Typography from "@material-ui/core/Typography";
import { Button, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import Styles from "../index.module.scss";
import UrlLinks from "../../../UrlLinks";
import Download from "../Group 3238.svg";
import { ScaleLoader } from "react-spinners";
import * as XLSX from "xlsx";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openCasperOnboardingModal,
    setOpenCasperOnboardingModal,
    showToast,
    updateMyData,
    partners,
    uniqueKey,
    title,
    option1,
    option2,
    downloadTitle,
    fileName,
  } = props;
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [casperLoading, setCasperLoading] = useState(false);
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [fileLink, setFileLink] = useState(null);
  const [responseData, setResponseData] = useState();
  const [actions, setActions] = useState(0);
  const [partnerId, setPartnerId] = useState("");
  const [diableBrowse, setDiableBrowse] = useState(true);
  const [closeFile, setCloseFile] = useState(true);

  const handleClose = () => {
    setOpenCasperOnboardingModal(false);
  };

  const handleCloseFile = () => {
    setName("");
    setCloseFile(true);
  };

  const handleUpload = (event) => {
    const fileExtension = event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(fileName);
      setExcel(event.target.files[0]);
      setExcelError(false);
      setCloseFile(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  };

  const createInvalidData = (records) => {
    if (records.length > 0) {
      let list = [];
      switch (uniqueKey) {
        case "UploadCasperID": {
          records.map((record) => {
            list.push({
              "Casper Id": record.swiggyId || "",
              "OkayGo id": record.okaygoId || "",
              "First Name": record.firstName || "",
              "Last Name": record.lastName || "",
              "Phone No": record.phoneNumber || "",
              "Reason for failure": record.reasonOfFailure || "",
            });
          });
          break;
        }
        case "UploadInsuranceTags": {
          records.map((record) => {
            list.push({
              "OkayGo Id": record.okaygoId || "",
              "Partner Name": record.partnerName || "",
              "Insurance tags": record.insuranceTags || "",
              "Reason for failure": record.reasonOfFailure || "",
            });
          });
          break;
        }
        default:
          break;
      }
      return list.length > 0 ? list : null;
    }
  };

  const createInvalidDataClients = (records) => {
    if (records.length > 0) {
      let list = [];
      switch (uniqueKey) {
        case "UploadCasperID": {
          records.map((record) => {
            list.push({
              [`${actions} Id`]: record.jiomartId || "",
              OkayGoId: record.okaygoId || "",
              "First Name": record.firstName || "",
              "Last Name": record.lastName || "",
              "Phone No": record.phoneNumber || "",
              "Reason for failure": record.reasonOfFailure || "",
            });
          });
          break;
        }
        case "UploadInsuranceTags": {
          records.map((record) => {
            list.push({
              "OkayGo Id": record.okaygoId || "",
              "Partner Name": record.partnerName || "",
              "Insurance tags": record.insuranceTags || "",
              "Reason for failure": record.reasonOfFailure || "",
            });
          });
          break;
        }
        default:
          break;
      }
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    switch (uniqueKey) {
      case "UploadCasperID": {
        Axios.post(
          `${UrlLinks.baseUrl}${
            UrlLinks.workerUrls.uploadCasperOnboardingData2
          }${actions}&insertedBy=${localStorage.getItem(
            "userID"
          )}&partnerId=${partnerId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then((response) => {
            if (actions === "Flipkart") {
              if (response.data.response) {
                if (response.data.response.invalidExcel) {
                  setInvalidData(
                    createInvalidData(response.data.response.invalidExcel)
                  );
                }
                let invalid = response.data.response.rejected;
                let uploaded = response.data.response.uploaded;
                let total = invalid + uploaded;
                const summary = {
                  "Total records attempted": total,
                  "Invalid data records": invalid,
                  "Workers onboarded": uploaded,
                };
                setResponseData(summary);
                updateMyData();
                setIsLoading(false);
              } else {
                setResponseData([]);
              }
            } else {
              if (response.data.response.invalidExcel) {
                setInvalidData(
                  createInvalidDataClients(response.data.response.invalidExcel)
                );
                let invalid = response.data.response.rejected;
                let uploaded = response.data.response.uploaded;
                let total = invalid + uploaded;
                const summary = {
                  "Total records attempted": total,
                  "Invalid data records": invalid,
                  "Workers onboarded": uploaded,
                };
                setResponseData(summary);
                updateMyData();
                setIsLoading(false);
              } else {
                setResponseData([]);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            showToast("error", error.message);
          });
        break;
      }

      case "UploadInsuranceTags": {
        Axios.post(
          `${UrlLinks.baseUrl}${
            UrlLinks.workerUrls.uploadInsuranceTags
          }${localStorage.getItem("userID")}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then((response) => {
            if (actions === "Flipkart") {
              if (response.data.response) {
                if (response.data.response.invalidExcel) {
                  setInvalidData(
                    createInvalidData(response.data.response.invalidExcel)
                  );
                }
                let invalid = response.data.response.invalidRecords;
                let uploaded = response.data.response.totalUploaded;
                let total = response.data.response.totalAttempted;
                const summary = {
                  "Total records attempted": total,
                  "Invalid data records": invalid,
                  "Workers onboarded": uploaded,
                };
                setResponseData(summary);
                updateMyData();
                setIsLoading(false);
              } else {
                setResponseData([]);
              }
            } else {
              if (response.data.response.invalidExcel) {
                setInvalidData(
                  createInvalidDataClients(response.data.response.invalidExcel)
                );
                let invalid = response.data.response.invalidRecords;
                let uploaded = response.data.response.totalUploaded;
                let total = response.data.response.totalAttempted;
                const summary = {
                  "Total records attempted": total,
                  "Invalid data records": invalid,
                  "Workers onboarded": uploaded,
                };
                setResponseData(summary);
                updateMyData();
                setIsLoading(false);
              } else {
                setResponseData([]);
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            showToast("error", error.message);
          });
        break;
      }
      default:
        break;
    }
  };

  const createWorkerWithoutData = (response) => {
    const list = [];
    response.map((record) => {
      list.push({
        [`${actions} Id`]: record.clientId,
        "OkayGo Id": record.okaygoId,
        "First Name": record.firstName,
        "Last Name": record.lastName,
        "Phone No": record.phoneNumber,
      });
    });
    return list;
  };

  const exportWorkerHandler = () => {
    const ws1 = XLSX.utils.json_to_sheet(fileLink, {});
    const ws2Data = [
      { "Insurance Tags": "GPA" },
      { "Insurance Tags": "GMC" },
      { "Insurance Tags": "Fidelity" },
    ];
    const ws2 = XLSX.utils.json_to_sheet(ws2Data, {});

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Sheet 1");
    uniqueKey === "UploadInsuranceTags" &&
      XLSX.utils.book_append_sheet(wb, ws2, "Tag Value");

    // Save the file
    XLSX.writeFile(wb, fileName);
  };

  function transformArray(array, keyToRemove) {
    // Mapping of keys to their transformed versions
    const keyMappings = {
      okaygoId: "OkayGo Id",
      partnerName: "Partner Name",
      insuranceTags: "Insurance tags",
    };

    // Function to transform a single object
    const transformObject = (obj) => {
      const keyOrder = ["okaygoId", "partnerName", "name", "phoneNumber", "insuranceTags"];
      const newObj = {};
      for (const key of keyOrder) {
        if (key in obj && key !== keyToRemove) {
          const transformedKey = keyMappings[key] || key;
          newObj[transformedKey] = obj[key];
        }
      }
      return newObj;
    };

    // Apply transformation to each object in the array
    return array.map(transformObject);
  }

  const getWorkerWithoutCasperID = async () => {
    setCasperLoading(true);
    let requestedBy = `&requestedById=${localStorage.getItem("userID")}`;

    if (uniqueKey === "UploadCasperID") {
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerWithoutClientCreatedIds}${actions}${requestedBy}&partnerId=${partnerId}`,
        null,
        { timeout: 60 * 5 * 1000 }
      )
        .then((response) => {
          setFileLink(createWorkerWithoutData(response.data.response));
        })
        .catch((error) => {
          setFileLink("");
          showToast("error", error.message);
        });
    } else {
      try {
        const response = await Axios.get(
          `${UrlLinks.baseUrl}${UrlLinks.workerUrls.getInsuranceTagsExport}${partnerId}`,
          null,
          { timeout: 60 * 5 * 1000 }
        );
        const transformedData = transformArray(
          response.data.response,
          "clientIdWorkerId"
        );
        setFileLink(transformedData);
      } catch (error) {
        console.log(`Error fetching data:`, error);
      }
    }
  };

  const handleActions = (event) => {
    setActions(event.target.value);
    let arrayObj = partners.filter((p) => {
      if (p.company_name === event.target.value) {
        return p.partner_id;
      }
    });
    setPartnerId(arrayObj[0].partner_id);
    setDiableBrowse(false);
    setExcel(null);
  };

  useEffect(() => {
    if (fileLink === "") {
      setCasperLoading(false);
    } else if (fileLink !== null) {
      setCasperLoading(false);
    }
  }, [fileLink]);

  useEffect(() => {
    if (actions) getWorkerWithoutCasperID();
    handleCloseFile();
  }, [actions]);

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  const loader = (
    <div style={{ margin: "130px auto", width: "fit-content" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openCasperOnboardingModal}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>{title}</span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container xs={12} className="padding0">
          <Grid item xs={4} className="padding0">
            <span style={{ fontWeight: "600", marginLeft: "20px" }}>
              {option1}
            </span>
          </Grid>
          <Grid item xs={8} className="padding0" alignItems="flex-start">
            <span>
              <FormControl
                variant="outlined"
                style={{
                  marginLeft: uniqueKey === "UploadInsuranceTags" ? "45px" : "",
                }}
              >
                <Select
                  id="actions"
                  value={actions}
                  classes={{ outlined: styles.actionSelect }}
                  onChange={handleActions}
                >
                  <MenuItem value={0} disabled={true}>
                    {" "}
                    Clients
                  </MenuItem>
                  {partners.map((e) => {
                    return (
                      <MenuItem key={e.company_name} value={e.company_name}>
                        {e.company_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </span>
          </Grid>
        </Grid>
        {casperLoading ? (
          loader
        ) : (
          <Grid container>
            <Grid item xs={12} className="padding0">
              {fileLink?.length > 0 ? (
                <Button
                  onClick={exportWorkerHandler}
                  className="download_template_btn"
                  style={{
                    marginLeft:
                      uniqueKey === "UploadInsuranceTags" ? "225px" : "190px",
                  }}
                >
                  <span style={{ marginRight: "5px" }}>{downloadTitle}</span>
                  <img src={Download} alt="download" />
                </Button>
              ) : (
                <span></span>
              )}
              <Grid
                item
                xs={12}
                className="padding10"
                style={{ marginTop: "10px" }}
              >
                <span
                  style={{
                    marginTop: "30px",
                    fontWeight: "600",
                    marginRight: "48px",
                  }}
                >
                  {option2}
                </span>
                <input
                  style={{ display: "none", marginLeft: "20px" }}
                  id="contained-button-file"
                  type="file"
                  disabled={diableBrowse}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    className={Styles.uploadButton}
                    component="span"
                    disabled={diableBrowse}
                    startIcon={<img src={document} alt={"upload"} />}
                  >
                    Browse File
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid item xs={12} className="padding0">
              <span
                style={{
                  marginLeft:
                    uniqueKey === "UploadCasperID" ? "180px" : "230px",
                }}
                className={`fontSize13 ${excelError ? "redColor" : ""}`}
              >
                only .xls and .xlsx are allowed
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              className="padding0"
              style={{ marginTop: "10px" }}
            >
              {name && (
                <span className={Styles.fileName}>
                  {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
                  <span>
                    <IconButton
                      aria-label="close"
                      style={{ color: "white", paddingRight: "0" }}
                      onClick={handleCloseFile}
                    >
                      <CloseIcon />
                    </IconButton>
                  </span>
                </span>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          updateMyData={updateMyData}
          setOpenOnboardingModal={setOpenCasperOnboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          type="OnboardingCasper"
        />
      )}
    </Dialog>
  );

  return dialogData;
}
