import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import Styles from "../index.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { open, close, recruiterList, handleReAssign } = props;
  const [recruiterAssigned, setRecruiterAssigned] = useState("");

  const handleupdate = () => {
    handleReAssign(recruiterAssigned);
    setRecruiterAssigned("");
    close(false);
  };

  let dialogData = (
    <Dialog
      aria-labelledby="status-update-dialog"
      open={open}
      className={Styles.reassignDialog}
      classes={{ paper: Styles.paperReassign }}
      onClose={() => close(false)}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => close(false)}>
        Re-Assign Retention call
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          id="ReAssig"
          value={recruiterAssigned}
          onChange={(e, newValue) => setRecruiterAssigned(newValue)}
          fullWidth
          options={recruiterList}
          classes={{}}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <React.Fragment>
              {option.id} - {option.name}{" "}
              {option.phoneNumber ? " - " + option.phoneNumber : ""}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Recruiter"
              size="small"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions className={Styles.reassignButtonContainer}>
        <button onClick={handleupdate}>Save</button>
      </DialogActions>
    </Dialog>
  );

  return dialogData;
}
