import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "../WorkerDataV2/index.module.scss";
import DateFilter from "../WorkerDataV2/FilterComponents/DateFilter";
import AutoSelectFilter from "../WorkerDataV2/FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../UrlLinks";

const FilterDrawer = (props) => {
  const { handleClose, resetFilters, applyFilters, filters, setFilters } =
    props;
  const [projectOwner, setProjectOwner] = useState([]);
  const [vertical, setVertical] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCancelFilters = () => {
    handleClose();
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const makeProjectOwnerData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.userId,
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
      });
      return true;
    });
    return list;
  };

  const makeVerticalData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeClientData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.partner_id,
        name: el.partner_name,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    if (projectOwner.length > 0) {
      setIsLoading(false);
    }
    if (vertical.length > 0) {
      setIsLoading(false);
    }
    if (clientList.length > 0) {
      setIsLoading(false);
    }
  }, [projectOwner, vertical, clientList]);

  useEffect(() => {
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.projectListing.projectOwner}`
    ).then((response) => {
      setProjectOwner(makeProjectOwnerData(response.data.response));
    });

    Axios.get(`${urlLinks.baseUrl}${urlLinks.projectListing.vertical}`).then(
      (response) => {
        setVertical(makeVerticalData(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.projectListing.client}`).then(
      (response) => {
        setClientList(makeClientData(response.data.response));
      }
    );
  }, []);

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={style.FilterContainer}
    >
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div className={style.flexBaseLine}>
          <Button onClick={handleClose} className={style.filterCloseArrow}>
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid container className={style.filtersContainer}>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Project owner</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="projectOwner"
            options={projectOwner}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Vertical</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="vertical"
            options={vertical}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Client</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="client"
            options={clientList}
          />
        </Grid>
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>Added on</span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="addedOn"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", border: "1px solid #1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              applyFilters();
              handleClose();
            }}
            className={style.PrimaryColorButton}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  return output;
};

export default FilterDrawer;
