import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "./index.module.scss";
import AutoSelectFilter from "./FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import DateFilter from "./FilterComponents/DateFilter";

const FilterDrawer = (props) => {
  const { handleClose, resetFilters, applyFilters, filters, setFilters } =
    props;
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelFilters = () => {
    handleClose();
  };

  const handleApply = () => {
    applyFilters();
    handleClose();
  };

  const claimStatusOptions = [
    {
      id: 1,
      name: "Approved",
    },
    {
      id: 2,
      name: "Not Approved",
    },
  ];

  const candidateStatusOptions = [
    {
      id: "Downloaded",
      name: "Downloaded",
    },
    {
      id: "Selected",
      name: "Selected",
    },
    {
      id: "Rejected",
      name: "Rejected",
    },
    {
      id: "Joined",
      name: "Joined",
    },
    {
      id: "Active",
      name: "Active",
    },
  ];

  const referralTypeOptions = [
    {
      id: "CONTACT_SHARED",
      name: "Contact Shared",
    },
    {
      id: "APP_DOWNLOAD",
      name: "App Download",
    },
  ];

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={style.FilterContainer}
    >
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div className={style.flexBaseLine}>
          <Button onClick={handleClose} className={style.filterCloseArrow}>
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid container className={style.filtersContainer2}>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Referral Type</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="referralType"
            options={referralTypeOptions}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Candidate Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="candidateStatus"
            options={candidateStatusOptions}
          />
        </Grid>
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Claim Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="claimStatus"
            options={claimStatusOptions}
          />
        </Grid>
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>Claim Date</span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="claimDate"
            />
          </Grid>
        </Grid>
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>Referral Date</span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="referralDate"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid
          container
          style={{ marginTop: "30px", marginBottom: "30px" }}
          className="padding0"
        >
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              color="primary"
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              handleApply();
              handleClose();
            }}
            className={style.PrimaryColorButton}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
