import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Tabs, Tab, Box, Grid, Divider} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import urlLinks from '../../../UrlLinks';
import Documents from './WorkerViewComponents/Documents'
import HRBPuse from './WorkerViewComponents/HRBPuse'
import PersonalDetails from './WorkerViewComponents/PersonalDetails'
import ProfilePhotoBlank from '../../../assets/profilePhoto.png'
import Axios from 'axios';
import {AuthContext} from '../../../services/AuthProvider'
import * as moment from 'moment'
import {  useLocation, useParams } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 424,
  },
  tabs: {
    width: "auto",
  },
  indicator: {
    backgroundColor:"unset",
    color: "#1c75bc",
    textDecoration: "underline",
  }
}));

export default function VerticalTabs(props) {
    const {id} =useParams();
    const classes = useStyles();
    const {showToast} = useContext(AuthContext);
    const [value, setValue] = useState(1);
    const [documentTypeList, setDocumentTypeList] = useState()
    const [documentList, setDocumentList] = useState()
    const [bankDetails, setBankDetails] = useState()
    const [data, setData] = useState()
    const [isLeadData,setisLeadData]= useState();
    const location = useLocation()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const createDocumentTypeList =(documents) =>{
        let list=[]
        documents.map( doc =>{
            const temp ={
                id: doc.id,
                type: doc.typeValue==="Aadhar Card"?"Aadhar Card Front":doc.typeValue==="Aadhar Back"?"Aadhar Card Back":doc.typeValue
            }
            list.push(temp)
            return true;
        })
        return list;
    }

    const createWorkerStatus =(status) =>{
        let text=""
        switch (status) {
            case 0:
                text="Not Onboarded";
                break;
            case 1:
                text="Active";
                break;
            case 2:
                text="Partially Onboarded";
                break;
            case 3:
                text="Inactive";
                break;
            case 5:
                text="Onboarding Completed";
                break;                    
            default:
                
                break;
        }
        return text;
    }
    
    const createDocumentList =(documents) =>{
        let list={}
        documents.map( doc =>{
            let obj = documentTypeList.find(type => type.id === doc.document_type_id);
            let documentName=""
            if(obj.type==="Aadhar Card Front"){
                documentName="aadhaarCard"
            }
            else if(obj.type==="Aadhar Card Back"){
                documentName="aadhaarCardBack"
            }
            else if(obj.type==="Id Proof"){
                documentName="idProof"
            }
            else if(obj.type==="Current Address Proof"){
                documentName="currentAddressProof"
            }
            else if(obj.type==="Driving License"){
                documentName="drivingLicense"
            }
            else if(obj.type==="RC"){
                documentName="vehicleRC"
            }
            else if(obj.type==="Pan Card"){
                documentName="panCard"
            }
            const docObj={
                name: `${obj.type}`,
                file: doc.file_path.replace("http://", "https://"),
                verified:doc.verified===null?0:doc.verified,
                reupload:doc.reupload_doc
            }
            console.log({docObj})
            list[documentName]=docObj;
            return true;
        })
        return list;

    }

    const getFlipkartData= (clientList) =>{
        let flipkartData={
            casperId: null,
            facilityName: null,
            facilityCity: null,
            facilityState: null,
            zone: null,
            facilityType: null,
            rateCard: null,
            date_of_joining: null,
            
        }
        clientList.map(flipkart =>{
            if(flipkart.clientName==="Flipkart"){
                flipkartData.casperId= flipkart.client_id
                flipkartData.facilityName= flipkart.facilityName
                flipkartData.facilityCity= flipkart.facilityCity
                flipkartData.facilityState= flipkart.facilityState
                flipkartData.zone= flipkart.zone
                flipkartData.facilityType= flipkart.facilityType
                flipkartData.rateCard= flipkart.rateCardType
                flipkartData.date_of_joining= flipkart.dateOfJoining?moment(flipkart.dateOfJoining).format("DD/MM/YYYY"):""
            }
        })
        return flipkartData
    }

    const makeData =(el, bankDetails) =>{
        return {
            ...getFlipkartData(el.clientList),
            facilityData: el.clientList || [],
            profileId: el.profileId ||"",
            name: `${el.firstName} ${el.lastName?el.lastName:""}`,
            status: createWorkerStatus(el.workerStatus),
            profilePhoto: el.profilePhoto?`${el.profilePhoto}`:"",
            firstName:el.firstName || "",
            lastName: el.lastName || "",
            edab: el.physicallyDisabled===1?"Yes":"No",
            criminalRecord: el.criminalRecord===0?"No":el.criminalRecord===1?"Yes":"",
            gender: el.gender==="26"?"Male":el.gender==="27"?"Female":el.gender==="30"?"Other":"",
            maritalStatus: el.maritalStatus===1871?"Single":el.maritalStatus===1872?"Married":el.maritalStatus===1873?"Other":"",
            languages: el.spokenLanguage ||"",
            present_address: el.curAddressLine1 || "", 
            present_city:el.curCity || "", 
            present_state: el.curStateName || "",
            permanent_address: el.perAddressLine1 || "", 
            permanent_city: el.perCity  || "", 
            permanent_state: el.perStateName || "",
            emergencyContactNo:el.emergencyContact || "",
            nomineeName:  el.nomineeName ||"",
            nomineeRelation: el.nomineeRelationship ||"",
            totalWorkExperience: (el.totalExpYear || el.totalExpMonth)?`${el.totalExpYear?el.totalExpYear:0} yrs ${el.totalExpMonth?el.totalExpMonth:0} months`:"",
            highestQualification: el.qualification||"",
            jobRolePreference: el.jobRolePreferenceName|| "",
            dob: el.dateOfBirth?moment(el.dateOfBirth).format("DD/MM/YYYY"):"",
            fathersName: el.fatherName ||"",
            qualification: el.qualification || "",
            aadhaar_number: el.aadhaarNumber ||"",
            jobRolePreferenceName: el.jobRolePreferenceName || "",
            contact: el.phoneNumber ||"",
            designation: el.designation || "",
            sourceBy: el.sourceBy || "",
            sourceByView: el.sourceBy==="VENDOR"?"Vendor":el.sourceBy==="TA"?"HRBP/TA":el.sourceBy==="EREF"?"Employee reference": "",
            sourceByName: el.sourceByName || "",
            okaygoId: el.okaygoId || "",
            clusterName: el.clusterName ||"",
            workerStatus: el.workerStatus,
            workerId: el.workerId,
            userId: el.userId,
            zomatoId:el.zomatoId||null,
            zomatoWorkerStatus:el.zomatoWorkerStatus!==null?el.zomatoWorkerStatus:null,
            swiggyWorkerStatus:el.swiggyWorkerStatus!==null?el.swiggyWorkerStatus:null,
            swiggyId:el.swiggyId||null,
            delhiveryId:el.delhiveryId||null,
            jiomartId:el.jiomartId||null,

             //delhivery
             delhiveryZone:el.delhiveryZone || "",
             delhiveryDateOfJoining:el.delhiveryDateOfJoining ? moment(el.delhiveryDateOfJoining).format("DD/MM/YYYY") : "",
             delhiveryInactiveDate:el.delhiveryInactiveDate? moment(el.delhiveryInactiveDate).format("DD/MM/YYYY") : "",
             delhiveryWorkerStatus:el.delhiveryWorkerStatus!==null?createWorkerStatus(el.delhiveryWorkerStatus):null,
             delhiveryactiveDate:el.delhiveryactiveDate? moment(el.delhiveryactiveDate).format("DD/MM/YYYY") : "",
             //jiomart
             jiomartZone:el.jiomartZone || "",
             jiomartDateOfJoining:el.jiomartDateOfJoining ? moment(el.jiomartDateOfJoining).format("DD/MM/YYYY") : "",
             jiomartInactiveDate:el.jiomartInactiveDate? moment(el.jiomartInactiveDate).format("DD/MM/YYYY") : "",
             jiomartWorkerStatus:el.jiomartWorkerStatus!==null?createWorkerStatus(el.jiomartWorkerStatus):null,
             jiomartactiveDate:el.jiomartactiveDate? moment(el.jiomartactiveDate).format("DD/MM/YYYY") : "",

            swiggyZone:el.swiggyZone||"",
            swiggyJoiningDate:el.swiggyJoiningDate?moment(el.swiggyJoiningDate).format("DD/MM/YYYY"):"",
            swiggyExitDate:el.swiggyExitDate?moment(el.swiggyExitDate).format("DD/MM/YYYY"):"",
            zomatoZone:el.zomatoZone||"",
            zomatoJoiningDate:el.zomatoJoiningDate?moment(el.zomatoJoiningDate).format("DD/MM/YYYY"):"",
            zomatoExitDate:el.zomatoExitDate?moment(el.zomatoExitDate).format("DD/MM/YYYY"):"",
            userGoogleLocation:el.userGoogleLocation||"",
            partnerName: el.partnerName?el.partnerName:"",
            clients:makeClients(el.facilityName,el.zomatoWorkerStatus,el.swiggyWorkerStatus,el.delhiveryWorkerStatus,el.jiomartWorkerStatus),
        }
    }

    const makeClients = (casperId, zomatoId, swiggyId,delhiveryId,jiomartId) => {
        let str = " ";
        if (casperId !== null && casperId !== undefined) {
            str = str + "Flipkart,"
        }
        if (zomatoId !== null && zomatoId !== undefined) {
            str = str + "Zomato,"
        }
        if (swiggyId !== null && swiggyId !== undefined) {
            str = str + "Swiggy,"
        }
        if (delhiveryId !== null && delhiveryId !== undefined) {
            str = str + "Delhivery,"
        }
        if (jiomartId !== null && jiomartId !== undefined) {
            str = str + "Jiomart,"
        }
        return str.substring(0, str.length - 1);
    }

    const makeBankDetails =(el) =>{
        if(!el){
            return[]
        }else{

        return {            
            accountNumber: el.account_number || "",
            accountHolderName: el.account_holder || "",
            ifscCode: el.ifsc_code || "",
            accountProof:el.file_path ||"",
            accountProofName: el.file_name || "",
        }}
    }

    const getBankDetails =() =>{
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.bankDetails}${parseInt(id)}`)
            .then(response =>{
                setBankDetails(makeBankDetails(response.data.response));
            })
            .catch(error =>{

            })
    }

    const getWorkerData =() =>{
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerViewV3}${parseInt(id)}`)
            .then(response =>{
                setData(makeData(response.data.response.content[0]))

            })
    }

    const getDocTypeList =() =>{
        Axios.get(`${urlLinks.baseUrl}${urlLinks.documentTypeList}`)
            .then(response =>{
                setDocumentTypeList(createDocumentTypeList(response.data.response.content))
            })
    }

    const getDocList =() =>{
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(id)}`)
            .then(response =>{
                setDocumentList(createDocumentList(response.data.response.content))
            })
    }
    
    useEffect(() => {
        getWorkerData();
        getDocTypeList();
        getBankDetails();
    }, [])

    useEffect(() => {
        if(documentTypeList?.length>0){
            getDocList()
        }
    }, [documentTypeList])

  return (
    !data?
        <div className="skeltonTabs">
            <Skeleton variant="text" />
            <Skeleton variant="circle" width={140} height={140} />
            <Skeleton variant="rect" height={118} />
        </div>:
        <Grid container className="jobDetail" style={{padding: "40px 20px"}} >
            <Grid container >
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={3} className='tabs' style={{backgroundColor: "white"}}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        classes={{ indicator: classes.indicator }} 
                        
                        className={`${classes.tabs} tabsParent`}
                    >
                    <Grid container  className="flexDisplay" direction ="column"> 

                        <Grid item xs={12} className="flexDisplay" direction ="column">
                            <div className="profilePhotoDiv">
                                {
                                    data.profilePhoto?
                                        <img src={data?.profilePhoto} crossOrigin="anonymous" alt="profile" className="profilePhoto" />:
                                        <img src={ProfilePhotoBlank} alt="profile blank" className="profilePhoto" />
                                }
                            </div>
                            {/* hey */}
                            {data.profilePhoto? <>
                            <span className="textAlignCenter fontSize1_5rem fontWeightBold"><a
                            style={{color:"blue", fontSize:'18px', paddingLeft: '1rem',paddingRight: '1rem' }}
                            download
                            href={data.profilePhoto}
                            >Download Link 1</a></span>
                            <span className="textAlignCenter fontSize1_5rem fontWeightBold"><a
                            style={{color:"blue", fontSize:'18px', paddingLeft: '1rem',paddingRight: '1rem' }}
                            download
                            href={data.profilePhoto.replaceAll("https","http")}
                            >Download Link 2</a>
                            </span>
                            </>:null}
                            <span className="textAlignCenter fontSize1_5rem fontWeightBold">{data.name}</span>
                            <span className="textAlignCenter fontSize_8rem paddingVertical6">OkayGo ID</span>
                            <span className="textAlignCenter fontSize_8rem paddingVertical6"><b>{data.okaygoId}</b></span>
                        </Grid>
                        <Divider className="margin20" />                     </Grid>
                    <Tab label="Personal Details" {...a11yProps(1)} className= "tabButtons fontSize1_1rem fontWeightBold" />
                    <Tab label="Documents" {...a11yProps(2)} className= "tabButtons fontSize1_1rem fontWeightBold" />
                    <Tab label="Contract Details" {...a11yProps(3)} className= "tabButtons fontSize1_1rem fontWeightBold" />
                    </Tabs>
                </Grid>
                <Grid item xs={7}>
                {isLeadData!=="" &&   <TabPanel value={value} index={1} className="tabPanel" style={{backgroundColor: "#ffffff"}}>
                        <PersonalDetails data= {data} documentList={documentList} />
                    </TabPanel>}
                    {isLeadData!=="" &&<TabPanel value={value} index={2} className="tabPanel" style={{backgroundColor: "#ffffff"}}>
                        <Documents data= {data} documentList={documentList} bankDetails={bankDetails} />
                    </TabPanel>}
                   {isLeadData!=="" && <TabPanel value={value} index={3} className="tabPanel" style={{backgroundColor: "#ffffff"}}>
                        <HRBPuse data= {data} documentList={documentList}  showToast={showToast} getWorkerData={getWorkerData} />
                    </TabPanel>}
                   
                </Grid>
            </Grid>
        </Grid>
  );
}
