import React, { useState, useEffect } from "react";
import {
  Grid
} from "@material-ui/core";
import Axios from "axios";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "./index.module.scss";
import DateFilter from "./FilterComponents/DateFilter";
import AutoSelectFilter from "./FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../UrlLinks";
import MultipleSelectFilter from "./FilterComponents/MultipleSelectFilter";
import { id } from "date-fns/locale";
import SearchSelectFilter from "../../Utils/FilterComponents/SearchSelectFilter";

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    applyFilters,
    filters,
    setFilters,
    tab,
    leadCityId,
    setLeadCityId,
    leadSourceId,
    setLeadSourceId,
    curLeadId,
    setCurLeadId,
  } = props;
  const [workerPartners, setWorkerPartners] = useState([]);
  const [hubFacilityList, sethubFacilityList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [hubstateList, sethubstateList] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [hubCityList, sethubCityList] = useState([]);
  const [shiftStatusList, setShiftStatusList] = useState([]);
  const [leadStateList, setLeadStateList] = useState([]);
  const [roleTypeList, setRoleTypeList] = useState([]);
  const [projectNameList, setProjectNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const yesNo = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 0,
      name: "No",
    },
  ];

  const lastAppliedOptions = [
    {
      id: 0,
      name: "Worker",
    },
    {
      id: 2,
      name: "Recruiter",
    },
    {
      id: 1,
      name: "Admin",
    },
  ];
  
  const clientStatus = [
    { id: 0, name: "Offboarded" },
    { id: 1, name: "ID Active" },
  ];

  const clientEarningStatus = [
    { id: -1, name: "No status" },
    { id: 0, name: "Inactive" },
    { id: 1, name: "Active" },
  ];

  const bgvStatusList = [
    { id: 0, name: "Not initiated" },
    { id: 1, name: "In Process" },
    { id: 2, name: "Verified" },
    { id: 3, name: "Failed" },
    { id: 4, name: "Incomplete Details" },
    { id: 5, name: "No Status" },
  ];

  const FA_LIST = [
    { id: 0, name: "Delivery Boy" },
    { id: 1, name: "Logistics Operation Executive" },
    { id: 2, name: "Driver" },
    { id: 3, name: "Loader" },
    { id: 4, name: "Purchase / Procurement Executive" },
    { id: 5, name: "Packer" },
  ];

  const IsLmaStatus = [
    { id: 0, name: "Eflex" },
    { id: 1, name: "LMA" },
    { id: 2, name: "Groupflex" },
  ];

  const trainingStatus = [
    {
      id: "Start Training",
      name: "Training Started",
    },
    {
      id: "1st day training done",
      name: "1st day training done",
    },
    {
      id: "2nd day training done",
      name: "2nd day training done",
    },
    {
      id: "3rd day training done",
      name: "3rd day training done",
    },
    {
      id: "Left during training",
      name: "Left during training",
    },
    {
      id: "Dropped",
      name: "Dropped",
    },
  ];

  const interviewStatus = [
    {
      id: "Interview Done",
      name: "Interview Done",
    },
    {
      id: "Dropped",
      name: "Dropped",
    },
    {
      id: "Rejected",
      name: "No Show",
    },
    {
      id: "Start Training",
      name: "Start Training",
    },
  ];

  const callStatus = [
    {
      id: "Interested",
      name: "Interested",
    },
    {
      id: "Call Again",
      name: "Call Again",
    },
    {
      id: "Not Interested",
      name: "Not Interested",
    },
    {
      id: "unActioned",
      name: "Unactioned",
    },
  ];

  const applicationStatus = [
    {id : -1, name : "Unactioned"},
    {id : 1, name : "Applied"},
    {id : 2, name : "Not Eligible"},
    {id : 3, name : "Backed Out"},
    {id : 4, name : "DNA"},
    {id : 5, name : "Interview Pending"},
    {id : 6, name : "Interview Done"},
    {id : 7, name : "Training Done"},
    {id : 8, name : "Training Pending"},
    {id : 9, name : "Ready to Work"},
    {id : 10, name : "Started Working"},
    {id : 11, name : "No Task Available"},
  ]

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const handleCancelFilters = () => {
    setLeadCityId(curLeadId.leadCity);
    setLeadSourceId(curLeadId.leadSource);
    handleClose();
  };
  
  const handleApplyFilters = () => {
    setCurLeadId({
      leadCity: leadCityId,
      leadSource: leadSourceId,
    });
    applyFilters();
    handleClose();
  };

  const makeWorkerPartnerData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.partner_id,
        name: el.company_name,
      });
      return true;
    });
    return list;
  };

  const makeShiftStatusData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeValue,
        name: el.typeDesc,
      });
      return true;
    });
    return list;
  };

  const makeHubData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.facilityName,
        name: el.facilityName,
      });
      return true;
    });
    return list;
  };

  const makeRecruiterData = (data) => {
    let list = [];
    list.push({
      id: -1,
      name: `Not Assigned`,
    });
    data.Content.map((el) => {
      if (el.isActive) {
        list.push({
          id: el.recruiterId,
          name: ` ${el.firstName} ${el.lastName ? " " + el.lastName : ""}`,
        });
      }
      return true;
    });
    return list;
  };

  const makeHubCityData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: index,
        name: el,
      });
      return true;
    });
    return list.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  };

  const makeLeadSourceData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: index,
        name: el,
      });
      return true;
    });
    return list;
  };

  const makeHubStateData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el,
        name: el,
      });
      return true;
    });
    return list;
  };

  const makeLeadStateData = (data) => {
    let list = [];
    data.map((el) => {
      let array = el.typeValue.split(",");
      array.map((element, index) => {
        list.push({
          id: index,
          name: element,
        });
      });
      return true;
    });
    return list;
  };

  const makeRoleTypeData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeProjectNameData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.projects_id,
        name: el.project_name,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    if (workerPartners.length > 0 && shiftStatusList.length > 0) {
      setIsLoading(false);
    }
  }, [workerPartners, shiftStatusList]);
  
  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`).then(
      (response) => {
        setWorkerPartners(makeWorkerPartnerData(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubs}`).then(
      (response) => {
        sethubFacilityList(makeHubData(response.data.response));
      }
    );
    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.recruiterList}`).then(
      (response) => {
        setRecruiterList(makeRecruiterData(response.data.response));
      }
    );

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubCity}`
    ).then((response) => {
      sethubCityList(makeHubCityData(response.data.response));
    });

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.leadSourceList}`).then(
      (response) => {
        setLeadSource(makeLeadSourceData(response.data.response));
      }
    );
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubState}`
    ).then((response) => {
      sethubstateList(makeHubStateData(response.data.response));
    });

    Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftStatusList}`).then(
      (response) => {
        setShiftStatusList(makeShiftStatusData(response.data.response.content));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.stateList}`).then((response) => {
      setLeadStateList(makeLeadStateData(response.data.response.content));
    });

    Axios.get(`${urlLinks.baseUrl}${urlLinks.roleType.leadCategory}`).then(
      (response) => {
        setRoleTypeList(makeRoleTypeData(response.data.response.content));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.projectListing.mainListing}`).then(
      (response) => {
        setProjectNameList(makeProjectNameData(response.data.response));
      }
    );
  }, []);

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div className={style.flexBaseLine}>
          <Button
            onClick={handleCancelFilters}
            className={style.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid container className={style.filtersContainer}>
        {tab.leadDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Lead/Job State</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="leadState"
              options={leadStateList}
            />
          </Grid>
        )}
        {tab.leadDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Lead City</span>
            <SearchSelectFilter
              filters={filters}
              setFilters={setFilters}
              id="leadCity"
              sourceId={leadCityId}
              setSourceId={setLeadCityId}
              module="leadCityListing"
              multiple={true}
              placeholder="Search and select"
              url={`${urlLinks.baseUrl}${urlLinks.workerUrls.leadCities}`}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Source</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="source"
              options={workerPartners}
            />
          </Grid>
        )}

        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Lead Source</span>
          <MultipleSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="leadSource"
            options={leadSource ? leadSource : ""}
            multipleFlag={true}
            sourceId={leadSourceId}
            setSourceId={setLeadSourceId}
          />
        </Grid>
        {tab.leadDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Functional Area</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="functionalArea"
              options={FA_LIST}
            />
          </Grid>
        )}
        {tab.leadDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Documents Available</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="docAvail"
              options={yesNo}
            />
          </Grid>
        )}
        {/* FA_LIST */}

        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>BGV Status</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="bgvStatus"
              options={bgvStatusList}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Modal flag</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="IsLmaStatus"
              options={IsLmaStatus}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Client</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="partnerId"
              options={workerPartners}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Client Status</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="clientStatus"
              options={clientStatus}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Client Earning Status</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="clientEarningStatus"
              options={clientEarningStatus}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Hub</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="facilityName"
              options={hubFacilityList}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Hub State</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="facilityState"
              options={hubstateList}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Hub City</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="facilityCity"
              options={hubCityList}
            />
          </Grid>
        )}
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Role Type</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="roleType"
            options={roleTypeList}
          />
        </Grid>
        {tab.leadDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Project Name</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="projectName"
              options={projectNameList}
            />
          </Grid>
        )}
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Recruiter assigned</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="recruiterId"
            options={recruiterList}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Last Applied By</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="lastAppliedBy"
            options={lastAppliedOptions}
          />
        </Grid>
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>Last Applied On</span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="lastAppliedOn"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Call Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="callStatus"
            options={callStatus}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Interview Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="interviewStatus"
            options={interviewStatus}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Application Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="applicationStatus"
            options={applicationStatus}
          />
        </Grid>
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Training Status</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="trainingUpdate"
              options={trainingStatus}
            />
          </Grid>
        )}
        {tab.leadDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Preferred Hub</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="preferedHub"
              options={hubFacilityList}
            />
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid item xs={12} className={`${style.singleFilterContainer}`}>
            <span className={style.filterTitle}>Bank details filled</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="bankDetails"
              options={yesNo}
            />
          </Grid>
        )}
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>Interview Date</span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="interviewDate"
            />
          </Grid>
        </Grid>
        {tab.workerDataTab && (
          <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>
              <span className={style.filterTitle}>First Earning Date</span>
            </Grid>
            <Grid xs={7}>
              <DateFilter
                filters={filters}
                setFilters={setFilters}
                labelTo="To"
                labelFrom="From"
                id="firstEarningDate"
              />
            </Grid>
          </Grid>
        )}
        {tab.workerDataTab && (
          <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>
              <span className={style.filterTitle}>Last Earning Date</span>
            </Grid>
            <Grid xs={7}>
              <DateFilter
                filters={filters}
                setFilters={setFilters}
                labelTo="To"
                labelFrom="From"
                id="lastEarningDate"
              />
            </Grid>
          </Grid>
        )}
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>Lead Added on</span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="leadAddedDate"
            />
          </Grid>
        </Grid>
        <Grid container xs={12} className={`${style.singleFilterContainer}`}>
          <Grid xs={5}>
            <span className={style.filterTitle}>
              Recruiter Assigned Between
            </span>
          </Grid>
          <Grid xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="RecruiterAssignedBetween"
            />
          </Grid>
        </Grid>
        {tab.workerDataTab && (
          <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>
              <span className={style.filterTitle}>ID Generation Date</span>
            </Grid>
            <Grid xs={7}>
              <DateFilter
                filters={filters}
                setFilters={setFilters}
                labelTo="To"
                labelFrom="From"
                id="idGenerationDate"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  return output;
};

export default FilterDrawer;
