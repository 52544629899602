import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { Button, Grid, Checkbox, Drawer } from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import { CSVLink } from "react-csv";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import Filters from "./filters";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import AssignRecruiter from "./components/ReassignModal";
import qs from "querystring";
import { AuthContext } from "../../services/AuthProvider";
import "./indeterminateCheckbox.css";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const rowClass = (row) => {
  if (row?.original) {
    let level = row.original.rewardLevel;
    let netPayableAmount = row.original.netPayableAmount;
    let compare;
    switch (level) {
      case 1:
        compare = 2500;
        break;
      case 2:
        compare = 5000;
        break;
      case 3:
        compare = 10000;
        break;
      case 4:
        compare = 20000;
        break;
      case 5:
        compare = 50000;
        break;
      default:
        compare = 70000;
    }
    if (netPayableAmount > compare) {
      return "greenRow row";
    } else {
      return "row";
    }
  } else {
    let level = row.rewardLevel;
    let netPayableAmount = row.netPayableAmount;
    let compare;
    switch (level) {
      case 1:
        compare = 2500;
        break;
      case 2:
        compare = 5000;
        break;
      case 3:
        compare = 10000;
        break;
      case 4:
        compare = 20000;
        break;
      case 5:
        compare = 50000;
        break;
      default:
        compare = 70000;
    }
    if (netPayableAmount > compare) {
      return true;
    } else {
      return false;
    }
  }
};

const useStyles = makeStyles((theme) => ({}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const ExternalWorker = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const { showToast } = useContext(AuthContext);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [disablePayments, setDisablePayments] = useState(true);
  const [recruiterList, setRecruiterList] = useState([]);
  const [partner, setPartner] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [recruiterAssign, setRecruiterAssign] = useState("");
  const [searchByUrl, setSearchByUrl] = useState("");
  const [disableReAssign, setDisableReAssign] = useState(true);
  const [sortUrl, setSortUrl] = useState("");
  const [filtersUrl, setFiltersUrl] = useState("");
  const [status, setStatus] = useState([]);
  const [dataToAprove, setDataToAprove] = useState([]);
  const [disableAprove, setDisableAprove] = useState(true);
  const [filters, setFilters] = useState({
    state: "",
    client: "",
    city: "",
    assignedTo: "",
    lastEarningDate: [null, null],
    status: "",
  });

  useEffect(() => {
    let disable = false;
    let cnt = 0;
    dataToAprove.forEach((data) => {
      if (data.original.netPayableAmount < data.original.rewardTarget) {
        disable = true;
        cnt++;
      }
    });

    if (cnt === 0 && dataToAprove.length === 0) {
      disable = true;
    }

    setDisableAprove(disable);
  }, [dataToAprove]);

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.getPartner}`).then((response) => {
      let a = [];
      response.data.response.map((e) => {
        a.push([e.partner_id, e.company_name]);
      });
      setPartner(a);
    });
  }, []);

  const makeRecruiterData = (data) => {
    let list = [];
    data.Content.map((el) => {
      if (el.isActive == 1) {
        list.push({
          id: el.recruiterId,
          name: ` ${el.firstName} ${el.lastName ? " " + el.lastName : ""}`,
          phoneNumber: el.phoneNumber,
        });
      }
      return true;
    });
    return list;
  };

  const handleAprove = (data) => {
    return new Promise((resolve, reject) => {
      let postData = {};
      Axios.post(
        `${urlLinks.aproveReward}?billFrom=${data.billFrom}&billTo=${data.billTo}&rewardId=${data.rewardLevel}&workerId=${data.workerId}`,
        qs.stringify(postData)
      )
        .then((response) => {
          if (response.status === 200) {
            resolve("success");
          } else if (response.data.code === 1001) {
            showToast("error", response.data.message);
            reject(response.data.message);
          }
        })
        .catch((error) => {
          showToast("error", error.message);
          reject(error.message);
        });
    });
  };

  const handleBulkAprove = (arr) => {
    Promise.all(
      dataToAprove.map(async (data) => {
        await handleAprove(data.original);
        return null;
      })
    ).then((res) => {
      showToast("success", `Reward is aproved to selected candidates`);
      getRewardData();
    });
  };

  const ApplyFilters = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["state"]) {
      url = `${url}&state=${filters["state"].name}`;
    }
    if (filters["client"]) {
      url = `${url}&partnerId=${filters["client"].id}`;
    }
    if (filters["city"]) {
      url = `${url}&city=${filters["city"].name}`;
    }
    if (filters["assignedTo"]) {
      url = `${url}&recruiterId=${filters["assignedTo"].id}`;
    }
    if (filters["lastEarningDate"][0] && !filters["lastEarningDate"][1])
      url = `${url}&lastEarningDateFrom=${moment(
        filters["lastEarningDate"][0]
      ).format("YYYY-MM-DD")}&lastEarningDateTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["lastEarningDate"][0] && filters["lastEarningDate"][1])
      url = `${url}&lastEarningDateFrom=2019-01-01&lastEarningDateTo=${moment(
        filters["lastEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["lastEarningDate"][0] && filters["lastEarningDate"][1])
      url = `${url}&lastEarningDateFrom=${moment(
        filters["lastEarningDate"][0]
      ).format("YYYY-MM-DD")}&lastEarningDateTo=${moment(
        filters["lastEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["status"]) {
      url = `${url}&status=${filters["status"].id}`;
    }
    setFiltersUrl(url);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };

  const resetFilters = () => {
    const filterList = {
      state: "",
      client: "",
      city: "",
      assignedTo: "",
      lastEarningDate: [null, null],
      status: "",
    };
    setFilters(filterList);
    setFiltersUrl("");
    setIsFilterApplied(false);
    setCurrentPage(1);
    return filterList;
  };

  useEffect(() => {
    Axios.get(
      `${urlLinks.baseUrl}configmaster/?category_sub_type=retention_call_status&category_type=retention_call_status`
    ).then(async (responseStatus) => {
      await setStatus(makeStatusData(responseStatus.data.response.content));
    });
    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.recruiterList}`).then(
      (response) => {
        setRecruiterList(makeRecruiterData(response.data.response));
      }
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps, rows }) => (
          <div>
            <IndeterminateCheckbox
              onClick={() => {
                if (!getToggleAllRowsSelectedProps().checked) {
                  setDataToAprove(rows);
                } else {
                  setDataToAprove([]);
                }
              }}
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <>
              {
                <div>
                  <IndeterminateCheckbox
                    onClick={() => {
                      if (!row?.isSelected) {
                        let newData = [...dataToAprove, row];
                        setDataToAprove(newData);
                      } else {
                        let newData = dataToAprove.filter(
                          (ele) => ele.id !== row.id
                        );
                        setDataToAprove(newData);
                      }
                    }}
                    {...row.getToggleRowSelectedProps()}
                    style={{ display: "flex", justifyItems: "center" }}
                  />
                </div>
              }
            </>
          );
        },
      },
      {
        Header: "OkayGo Id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "User Id",
        accessor: "userId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Worker Id",
        accessor: "workerId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Reward Level",
        accessor: "rewardLevel",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "User Name",
        accessor: "userName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Reward Amount",
        accessor: "rewardAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: true,
      },
      {
        Header: "Bank Detail Status",
        accessor: "bankDetailStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: false,
      },
      {
        Header: "Verification Status",
        accessor: "verificationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Earning Shipment",
        accessor: "earningShipment",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bill From",
        accessor: "billFrom",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bill To",
        accessor: "billTo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Reward Target",
        accessor: "rewardTarget",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Net Payable Amount",
        accessor: "netPayableAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [
      filters,
      filtersUrl,
      sortBy,
      sortUrl,
      perPage,
      currentPage,
      dataToAprove,
      disableAprove,
    ]
  );

  //Function to create tale data
  const makeData = (response) => {
    let extractedData = [];
    response.map((el) => {
      extractedData.push({
        okaygoId: el.okaygo_id || "",
        workerId: el.worker_id || "",
        userId: el.user_id || "",
        rewardLevel: el.reward_level || "",
        rewardTarget: el.reward_target || "",
        userName: el.user_name || "",
        phoneNumber: el.phone_number || "",
        rewardAmount: el.reward_amount || "",
        bankDetailStatus: el.bank_detail_status || "",
        verificationStatus: el.verification_status || "",
        earningShipment: el.earning_shipments || "",
        billFrom: moment(el.bill_from).format("DD-MM-YYYY") || "",
        billTo: moment(el.bill_to).format("DD-MM-YYYY") || "",
        netPayableAmount: el.net_payable_amount,
      });
      return true;
    });

    return extractedData;
  };

  //Function to create All record export data

  const updateMyData = () => {
    getRewardData(perPage, currentPage);
  };

  const makeStatusData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getRewardData = () => {
    setLoading(true);

    Axios.get(`${urlLinks.baseUrl}${urlLinks.rewardPaymentData}`)
      .then((response) => {
        if (response) {
          setTotalRows(response.data.data.length);
          setTotalPages(1);
          if (currentPage > 0) setCurrentPage(1);
          setData(makeData(response.data.data));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (status.length > 0) {
      getRewardData(perPage, 1);
    }
  }, [
    filtersUrl,
    sortUrl,
    localStorage.getItem("roleType"),
    searchByUrl,
    status,
  ]);

  const handlePageChange = (page) => {
    getRewardData(perPage, page);
    setCurrentPage(page);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [""],
      },
      updateMyData,
      showToast,
      setLoading,
      filters,
      status,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getRewardData(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const sortByGreen = () => {
    let greenData = data.filter((obj) => rowClass(obj));
    setData(greenData);
    setTotalRows(greenData.length);
  };

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisableReAssign(disableButton);
    }
  }, [selectedRowIds]);

  const handleReAssign = (recruiter) => {
    setRecruiterAssign("");
    const indexes = Object.keys(selectedRowIds);
    let userIdList = [];
    indexes.map((id) => {
      userIdList.push(data[id].retentionCallId);
    });
    let postData = {
      recruiterId: recruiter.id,
      retentionCallIds: userIdList,
    };
    Axios.post(
      `${urlLinks.baseUrl}${urlLinks.retentionCalls.AssignRecruter}`,
      qs.stringify(postData)
    )
      .then((response) => {
        if (response.data.code === 1000) {
          showToast("success", `Users are assigned to ${recruiter.name}`);
          toggleAllRowsSelected(false);
          updateMyData();
        } else if (response.data.code === 1001) {
          showToast("error", response.data.message);
        }
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === ""
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === ""
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={rowClass(row)}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "W"
                            ? ``
                            : cell.column.Header === "OkayGo id" ||
                              cell.column.Header === "Recruiter ID"
                            ? "stickOkayGoId zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickPaymentNameV2Trans zIndex540"
                            : cell.column.Header === "Name"
                            ? `stickPaymentNameV2 zIndex540 ${
                                row.original.has_desc === 1
                                  ? "criminalRecord"
                                  : ""
                              }`
                            : cell.column.id === "selection"
                            ? `stickCheckbox zIndex540 ${
                                row.original.has_desc === 1
                                  ? "criminalRecord"
                                  : ""
                              }`
                            : cell.column.Header === "Mark onHold"
                            ? "displayNone"
                            : `cell ${
                                row.original.has_desc === 1
                                  ? "criminalRecord"
                                  : ""
                              }
                                                     ${rowClass(row)}`
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid container xs={12}>
          <Grid>
            <span className="fontSize24 fontWeightBold">Reward Report</span>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16"
          direction="row"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={6}
            className=""
            justifyContent="flex-end"
            style={{ direction: "rtl" }}
          >
            <Button
              variant="contained"
              className="dataButton"
              disabled={allRecordLoading}
              onClick={sortByGreen}
              style={{ marginLeft: "25px" }}
            >
              Green Cases
            </Button>
            <Button
              variant="contained"
              className="dataButton"
              disabled={allRecordLoading}
              onClick={getRewardData}
              style={{ marginLeft: "25px" }}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              className="dataButton"
              disabled={disableAprove}
              onClick={() => handleBulkAprove()}
              style={{ marginLeft: "25px" }}
            >
              Approve
            </Button>
            {allRecordLoading ? (
              <span style={{ marginLeft: "20px" }}>
                Please wait ... it might take few minutes.{" "}
              </span>
            ) : allRecord.length > 0 ? (
              <CSVLink
                data={allRecord}
                filename={`RetentionCallList_${moment().format(
                  "DD/MM/YYYY HH:mm"
                )}.csv`}
                className="btn btn-primary blueColor downloadExcelButton"
                target="_blank"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
              >
                Download now
              </CSVLink>
            ) : null}
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}
        >
          <Filters
            handleClose={() => setOpenFilterDrawer(false)}
            filters={filters}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFilters}
          />
        </Drawer>
        {
          <AssignRecruiter
            open={openReassignModal}
            close={setOpenReassignModal}
            recruiterList={recruiterList}
            handleReAssign={handleReAssign}
          />
        }
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
